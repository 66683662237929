import * as React from "react";

import { ImportationStatus } from "../../models/ImportationStatus";
import { SpinnerInline } from "../common/SpinnerInline";

import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button, Col, Row } from "reactstrap";

interface ImportationProgressProps {
    logoUrl: string;
    status: ImportationStatus;
    onClick: () => void;
}



export const ImportationProgressFn = function (props: ImportationProgressProps & WithTranslationProps) {


    if (props.status == null) {
        return <div></div>;
    }

    return <React.Fragment>
        <Row>
            <Col md={3}>
                <img className="me-3 logo mx-auto" src={props.logoUrl} alt="" />
            </Col>
            <Col md={9}>

                <div className="text-center">
                    {!props.status.Finished &&
                        <div className="text-center">
                            <h4 className="mt-4">{props.status.Message}</h4>
                            <SpinnerInline />
                        </div>
                    }

                    {!props.status.HasError && props.status.Finished &&
                        <div className="text-center text-success">
                            <h4 className="mt-4">{props.i18n.t("importations:importationOk")}</h4>
                            <p>{props.status.Message}</p>
                        </div>
                    }

                    {props.status.HasError && props.status.Finished &&
                        <div className="text-center text-danger">
                            <h4 className="mt-4">{props.i18n.t("importations:importationError")}</h4>
                        </div>
                    }

                </div>
            </Col>
        </Row>

        {props.status.Finished && <React.Fragment>
            <Row className="mt-4">
                <Col>

                    <Button color="primary" block onClick={() => props.onClick()}>
                        OK
                    </Button>

                </Col>
            </Row>
        </React.Fragment>}

        {props.status.HasError && (props.status.Details == null || props.status.Details.length === 0) && <Row>
            <Col md={12}>
                <span className="text-danger">{props.i18n.t("importations:genericError")}</span>
            </Col>
        </Row>}

        {props.status.Details != null && props.status.Details.length !== 0 && <Row>
            <Col md={12}>
                <ul className="importResult">
                    {props.status.Details.map((item, i) => {

                        var className = item.IsOk ? "" : "text-danger";

                        return <li className={className}>
                            {item.Error}
                        </li>;
                    })}
                </ul>
            </Col>
        </Row>}

        {props.status.Finished && !props.status.HasError && (props.status.Details != null && props.status.Details.length === 0) && <Row>
            <Col md={12}>
                <span className="text-warning">{props.i18n.t("importations:noData")}</span>
            </Col>
        </Row>}

        {!props.status.Finished && <React.Fragment>
            <Row></Row>
            <Row>
                <Col md={12}>
                    <p className="text-sm text-center mt-4 mb-0" dangerouslySetInnerHTML={{ __html: props.i18n.t("dataSources:WarningTime") }}></p>
                </Col>
            </Row>
        </React.Fragment>}

    </React.Fragment>;


};


export const ImportationProgress = withTranslation(["importations"])(ImportationProgressFn);

