import React, { Component } from 'react';
import { FaSync } from "react-icons/fa";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button, Modal, ModalFooter, ModalHeader} from "reactstrap";
import { QuoteInfo, QuoteService } from "./QuoteService";
import { SpinnerInline } from '../common/SpinnerInline';

import { formatCurrency } from '../common/Formatter';

interface NewQuoteButtonState {
    showNewModal: boolean;
    moreInfo: string;
    initLoading: boolean;
    quoteInfo: QuoteInfo;
}

export class NewQuoteButtonComp extends Component<WithTranslationProps, NewQuoteButtonState>     {


    public constructor(props: any) {
        super(props);
        this.state = {
            showNewModal: false,
            moreInfo: null,
            initLoading: true,
            quoteInfo: null
        };
    }

    async componentDidMount() {
        await this._readQuote();
    }

    private async _readQuote() {
        var quote = await QuoteService.getLastQuote();

        this.setState({
            initLoading: false,
            quoteInfo: quote,
            showNewModal: false
        });
    }

    render() {
        const { i18n } = this.props;

        if (this.state.initLoading) {
            return <div className="text-center">
                <SpinnerInline />
            </div>;
        }

        if (this.state.quoteInfo != null && (this.state.quoteInfo.Id || 0) !== 0 && this.state.quoteInfo.ResponseDate == null) {
            return <div className="home-quote">
                <h2>{i18n.t("home:quoteInProgressTitle")}</h2>
                <br />
                <p>{i18n.t("home:quoteInProgressSubtitle")}</p>
            </div>;
        }

        if (this.state.quoteInfo != null && (this.state.quoteInfo.Id || 0) !== 0 && this.state.quoteInfo.ResponseDate != null) {
            return <div className="home-quote">
                <div>
                    <h2>{i18n.t("home:quoteValue")}: </h2>

                    <h2 className="valuation">{formatCurrency(i18n.language, this.state.quoteInfo.QuoteAmount)}</h2>

                    {this.state.quoteInfo.ResponseObservations != null && <React.Fragment>                        
                        <p>
                            <b>{i18n.t("home:valuationNotes")}:</b>&nbsp;
                            {this.state.quoteInfo.ResponseObservations}
                        </p>
                    </React.Fragment>}

                    <div className="text-center">

                        <Button onClick={() => this.setState({ showNewModal: true })}>
                            <FaSync />&nbsp;&nbsp;{i18n.t("home:refreshQuote")}
                        </Button>

                        <p className="mt-2">
                            {i18n.t("home:valuationDate")}{' '}
                            {this.state.quoteInfo.ResponseDate.toLocaleDateString(i18n.language)}

                        </p>
                    </div>
                </div>
                {this._showModal()}

            </div>;
        }


        return <React.Fragment>

            <div className="home-quote">
                <div>
                    <h2>{i18n.t("home:quoteTextTitle")}</h2>
                    <p>{i18n.t("home:quoteTextSubtitle")}</p>
                    <Button onClick={() => this.setState({ showNewModal: true })}>
                        {i18n.t("home:quoteButtonText")}
                    </Button>
                </div>
            </div>
            {this._showModal()}

        </React.Fragment>;
    }

    private _showModal(): JSX.Element {

        var i18n = this.props.i18n;

        if (this.state.showNewModal) {
            return <Modal title={i18n.t("home:quoteButtonText")}
                isOpen={this.state.showNewModal}
                onClosed={() => this._closeModal()}
                size="lg"
            >

                <ModalHeader>{i18n.t("home:quoteButtonText")}</ModalHeader>

                <div style={{ padding: "20px 30px" }}>
                    {i18n.t("home:requestQuoteDescription")}


                </div>
                <div className="ml-4 mr-3">
                    <div className="pb-3"><b>{i18n.t("home:requestQuoteMoreInfo")}</b></div>
                    <textarea
                        className="form-control" rows={6}
                        onChange={(evt) => this.setState({ moreInfo: evt.target.value })}
                    />
                </div>
                <ModalFooter>
                    <Button color="primary" size="lg" onClick={() => this._createQuote()}>
                        {i18n.t("home:requestQuote")}
                    </Button>
                    <Button color="secondary" onClick={() => this._closeModal()}>
                        {i18n.t("home:cancel")}
                    </Button>
                </ModalFooter>
            </Modal>;
        }
    }

    private async _createQuote() {
        var result = await QuoteService.createQuote({
            publicMusicianId: null,
            extraInfo: this.state.moreInfo
        });

        if (result.IsOk) {
            this._readQuote();
        }
        else {
            alert("Ha sucedido un error, int�ntelo mas tarde");
            this._closeModal();
        }
    }

    private _closeModal() {
        this.setState({
            moreInfo: null,
            showNewModal: false
        });
    }
}

export const NewQuoteButton = withTranslation(["home"])(NewQuoteButtonComp);
