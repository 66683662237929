import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Modal, ModalBody } from 'reactstrap';

import { PetitionHelper } from "../../utils/PetitionHelper";
import { Spinner } from "../../common/Spinner";

import { ImportationStatus } from "../../../models/ImportationStatus";
import { ImportationProgress } from "../ImportationProgress";


export interface ReimportCompProps {
    onProcessFinished?: (message: string) => void;
}


export interface GenericReimportCompProps extends ReimportCompProps {
    logoUrl: string;
    serviceUrl: string;
}

interface ReimportCompState {

    loading: boolean;
    importationStatus: ImportationStatus;
    
}

class GenericReimportComp extends React.Component<WithTranslationProps & GenericReimportCompProps, ReimportCompState> {

    public constructor(props: WithTranslationProps & GenericReimportCompProps) {
        super(props);

        this.state = {
            loading: true,
            importationStatus: null,
        };

    }

    async componentDidMount() {
        await this.doReimport();
    }

    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        const { i18n } = this.props;

        return <Modal isOpen={true}>
            <ModalBody>
                <div>
                    <h3 className='text-center'>{i18n.t("dataSources:Step2")}</h3>

                    <ImportationProgress
                        logoUrl={this.props.logoUrl}
                        i18n={this.props.i18n}
                        status={this.state.importationStatus}
                        onClick={() => this.props.onProcessFinished(this.state.importationStatus.Message)}
                    />

                    
                </div>
            </ModalBody>
        </Modal>;
    }

    private async doReimport() {
        this.setState({ importationStatus: {} as any }, async () => {
            try {
                let requestId = await PetitionHelper.MakePostPetition(`${this.props.serviceUrl}/Reimport`, {}) as any;

                this.setState({loading: false}, () => {

                    let timerId = window.setInterval(
                        async () => {
                            let result = await PetitionHelper.MakeGetPetition(`${this.props.serviceUrl}/GetStatus?requestId=${requestId}`) as ImportationStatus;
                            if (result.Finished) {
                                window.clearInterval(timerId);
                            }

                            this.setState({ importationStatus: result });
                        },
                        2000);
                });
            }
            catch (error) {
                alert("Error");
            }
        });
    }


}

export const GenericReimport = withTranslation(["dataSources"])(GenericReimportComp);

