import * as React from "react";
import Loader from "react-loader-spinner";

export class Spinner extends React.Component {

    render() {
        return <div style={{
            position: "fixed", top: 0,
            left: 0, backgroundColor: "#000000",
            opacity: 0.45, height: "100%",
            width: "100%", display: "table",
            zIndex: 9999
        }}>
            <div className="align-middle text-center d-table-cell" >
                <Loader type="Bars" color="#F6BE00" height={100} width={100} />
            </div>
        </div>;
    }

};