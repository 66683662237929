import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Jumbotron } from 'reactstrap';


class NotFoundComp extends Component<WithTranslationProps & RouteComponentProps, any> {
   
    render() {

        return (
            <div className="align-middle">
                <Container>
                    <Jumbotron>
                        <Container fluid>
                            <h1 className="display-3">404 - Not Found</h1>
                        </Container>
                    </Jumbotron>

                </Container>
            </div>
        );
    }


};

export const NotFound = withTranslation([])(NotFoundComp);
