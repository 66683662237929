import React, { Component, useRef } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Jumbotron, Label, Row } from 'reactstrap';
import { ClientContext } from './security/ClientContext';
import { ModelValidation } from "./utils/ModelValidation";
import { PetitionHelper } from "./utils/PetitionHelper";
import { Spinner } from "./common/Spinner";
import { ReCAPTCHA } from "react-google-recaptcha";

interface LoginState {
    showLoading: boolean;
    userName: string;
    password: string;
    recaptchaVal: string;
    validation: ModelValidation;
}

class LoginComp extends Component<WithTranslationProps & RouteComponentProps, LoginState> {


    private captchaRef: ReCAPTCHA;


    constructor(props: any) {
        super(props);
        this.state = {
            showLoading: false,
            userName: "",
            password: "",
            recaptchaVal: "",
            validation: new ModelValidation()
        };
    }

    render() {

        const { i18n } = this.props;

        return (
            <div className="align-middle">
                {this.state.showLoading && <Spinner />}
                <Row>
                    <Col md={5} lg={6}>
                        <Jumbotron>
                            <Container fluid>
                                <h1>{i18n.t("login:mainTitle")}</h1>
                                <p className="lead">{i18n.t("login:mainSubTitle")}</p>
                                <img className="img-fluid d-none d-md-block" src="/assets/img/girl.png" alt="" />
                            </Container>
                        </Jumbotron>
                    </Col>
                    <Col md={7} lg={6} className="form-content">
                        <img className="text-center mb-3 d-none d-md-block" src="/assets/img/logo.png" alt="MusicTraders" />
                        <p className="lead d-none mb-5 d-md-block">{i18n.t("login:loginTitle")}</p>
                        <Form className="login" onSubmit={() => this.logIn()} >
                            <Row>
                                <Col md={12}>
                                    <FormGroup row className="border-label">

                                        <Label for="login" >
                                            {i18n.t("login:username")}
                                        </Label>
                                        <Input type="text" name="login" id="login"
                                            className="form-control"
                                            value={this.state.userName}
                                            onChange={(evt) => this.setState({ userName: evt.target.value })}
                                            invalid={this.state.validation.hasError("userName")}
                                        />
                                        {this._errorMessage("userName")}

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup row className="border-label">

                                        <Label for="password">{i18n.t("login:password")}</Label>

                                        <Input type="password" name="password" id="password"
                                            value={this.state.password}
                                            onChange={(evt) => this.setState({ password: evt.target.value })}
                                            invalid={this.state.validation.hasError("password")}
                                        />
                                        {this._errorMessage("password")}

                                    </FormGroup>
                                </Col>
                            </Row>


                            <FormGroup row className="justify-content-center">
                                
                                    <ReCAPTCHA
                                        sitekey="6Lc_tFIoAAAAAIVE3XIeYXITjczFeh57Ewfzb04Q"
                                        grecaptcha={(window as any).grecaptcha}
                                        ref={(val) => this.captchaRef = val}
                                        theme="dark"
                                        onErrored={(err) => {
                                            debugger;
                                        }}
                                        onChange={(token: string) => {
                                            this.setState({ recaptchaVal: token });
                                        }}
                                    />

                                    {this.state.validation.validationErrors["recaptcha"] != null &&
                                        <div style={{ textAlign: "center", width: "100%", color: "#dc3545" }}>
                                            {this.state.validation.validationErrors["recaptcha"]}
                                        </div>
                                    }
                                
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={6}>
                                    <Button block
                                        size="lg"
                                        color="primary" onClick={() => this.logIn()}> {i18n.t("login:login")}</Button>
                                </Col>
                                <Col sm={6} className="text-center">
                                    <Button outline color="secondary" block size="lg"
                                        onClick={() => this.props.history.push("/signup")}>
                                        {i18n.t("login:signUp")}
                                    </Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>

                                <Col sm={6}>
                                    <Link to="/recover-password" className="">
                                        {i18n.t("login:forgotPassword")}
                                    </Link>
                                </Col>
                            </FormGroup>


                        </Form>


                    </Col>
                </Row>
            </div>
        );


    }



    private async logIn() {
       
        let validation = await this.validateForm();
        if (validation.isOk) {

            try {

                var result = await LoginService.MakeLogin(this.state.userName, this.state.password, this.state.recaptchaVal);

                if (!result.IsValid) {
                    let newVal = new ModelValidation();
                    newVal.addError("password", this.props.i18n.t("login:invalidLogin"));

                    this.setState({ validation: newVal });
                }
                else {
                    ClientContext.load(result.Token);
                    this.props.history.push("/");
                }
            }
            catch (error) {
                alert(error);
            }
        }
        else {
            this.setState({ validation: validation });
        }
    }

    private _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validation.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    private async validateForm(): Promise<ModelValidation> {
        
        var validation = new ModelValidation();
        if ((this.state.userName || "") === "") {
            validation.addError("userName", this.props.i18n.t("errors:required"));
        }

        if ((this.state.password || "") === "") {
            validation.addError("password", this.props.i18n.t("errors:required"));
        }

        if ((this.state.recaptchaVal || "") === "") {
            validation.addError("recaptcha", this.props.i18n.t("errors:selectCaptcha"));
        }

        return validation;
    }
};

export const Login = withTranslation(["login", "errors"])(LoginComp);

class LoginService {

    public static async MakeLogin(login: string, password: string, recaptchaVal: string): Promise<LoginResult> {


        var loginMessage = {
            Login: login,
            Password: password,
            RecaptchaVal: recaptchaVal
        } as LoginRequest;

        var result = await PetitionHelper.MakePostPetition("Services/Custom/Login", loginMessage) as LoginResult;

        return result;

    }

}

interface LoginResult {
    IsValid: boolean;
    Token: string;
}

interface LoginRequest {
    Login: string;
    Password: string;
    RecaptchaVal: string;
}
