import * as React from "react";
import { SongDataItem } from "../../models/SongDataItem";
import { Col, Row } from "reactstrap";
import { getFromToDate, SalesPeriod, SalesPeriodDropDown } from "../common/SalesPeriodDropDown";
import { SongsDataService, SongsData } from "./SongsDataService";
import { Spinner } from "../common/Spinner";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Link } from 'react-router-dom';
import { formatCurrencyNoSymbol } from "../common/Formatter";

interface TopSongsProps {
    title: string;
    numSongs: number;
    total: number;
    salesPeriod: SalesPeriod,
    onDataRead?: (data: SongDataItem[]) => void;
}

interface TopSongsState {

    loading: boolean;
    songsData: SongsData;
    totalSales: number,
    totalSum: number,
    salesPeriod: SalesPeriod,
}


export class TopSongsComp extends React.Component<WithTranslationProps & TopSongsProps, TopSongsState> {



    public constructor(props: TopSongsProps) {
        super(props);


        this.state = {
            loading: true,
            songsData: null,
            totalSales: props.total,
            totalSum: 0,
            salesPeriod: props.salesPeriod,

        };
    }

    async componentDidMount() {
        await this.searchSales();
    }

    private async searchSales() {

        var period = getFromToDate(this.state.salesPeriod);

        var dataResponse = await SongsDataService.getSongsData({
            pageSize: this.props.numSongs,
            fromDate: period.from,
            toDate: period.to,
            pageNumber: 0,
            showTotal: false
        });

        this.setState({
            loading: false,
            songsData: dataResponse
        },
            () => {
                if (this.props.onDataRead) {
                    this.props.onDataRead(dataResponse.Data);
                }
            }
        );
    }

    static getDerivedStateFromProps(props: TopSongsProps, state: TopSongsState) {
        if (props.numSongs !== state.totalSales) {
            return {
                ...state,
                totalSales: props.total
            } as TopSongsState;
        }

        return null;
    }

    render() {
        const { i18n } = this.props;

        if (this.state.loading) {
            return <Spinner />;
        }

        var salesPeriodDropDown = <SalesPeriodDropDown
            selectedItemValue={this.state.salesPeriod}
            onChanged={async (val) => {

                this.setState({ salesPeriod: val, loading: true },
                    () => {
                        this.searchSales();
                    });
            }}
        />;

        return (
            <div className="data-table">
                <Row>
                    {this.props.title != null &&
                        <React.Fragment>
                            <Col md={12} lg={7}>
                                <h3>{this.props.title}</h3>
                            </Col>
                            <Col md={12} lg={5}>
                                {salesPeriodDropDown}
                            </Col>
                        </React.Fragment>
                    }
                    {this.props.title == null &&
                        <Col md={12}>
                            {salesPeriodDropDown}
                        </Col>
                    }
                </Row>


                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th>{i18n.t("songs:tableTitle")}</th>
                            <th>{i18n.t("songs:tableTotalInUSD")}</th>
                            <th>{i18n.t("songs:tablePercentaje")}</th>
                            <th>{i18n.t("songs:tableFirstSale")}</th>
                            <th>{i18n.t("songs:tableLastSale")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.songsData.Data.length === 0 && <tr><td colSpan={5}>{i18n.t("songs:emptyList")}</td></tr>}
                        {this.state.songsData.Data.map((song, index) => {

                            var totalSales = this.state.totalSales == null
                                ? this.calculateTotal(song.TotalInUSD, this.state.totalSum)
                                : this.calculateTotal(song.TotalInUSD, this.state.totalSales);

                            return <tr key={index}>
                                <td className="song"><Link to={"/songs/" + song.Id}>{song.Title.toLowerCase()}</Link></td>
                                <td>{formatCurrencyNoSymbol(i18n.language, song.TotalInUSD)}</td>
                                <td>{formatCurrencyNoSymbol(i18n.language, totalSales)}</td>
                                <td>{song.FirstSale.toLocaleDateString()}</td>
                                <td>{song.LastSale.toLocaleDateString()}</td>
                            </tr>;
                        })}
                    </tbody>
                </table>

            </div >

        );
    }

    private calculateTotal(song: number, total: number): number {

        var percentage = ((100 * song) / total);

        return percentage;
    }
}

export const TopSongs = withTranslation(["songs"])(TopSongsComp);