import { SongDataItem, SalesByStoreItem } from "../../models/SongDataItem";
import { PetitionHelper } from "../utils/PetitionHelper";

export class SongsDataService {

    static async getSongsData(query: SongsQueryData): Promise<SongsData> {

        var result = await PetitionHelper.MakeGetPetitionWithData("Services/Custom/GetSongsSalesData", query) as SongsData;

        result.Data.forEach((songData, index) => {
            songData.FirstSale = PetitionHelper.ParseDate(songData.FirstSale);
            songData.LastSale = PetitionHelper.ParseDate(songData.LastSale);
        })

        return result;
    }

    static async getSongsSalesByStoreData(query: SalesByStoreQueryData): Promise<SalesByStoreItem[]> {

        var result = await PetitionHelper.MakeGetPetitionWithData("Services/Custom/GetSongsSalesByStoreData", query) as SalesByStoreItem[];

        return result;
    }
}


interface SongsQueryData {
    fromDate: Date;
    toDate: Date;
    pageNumber: number;
    pageSize?: number;
    numStores?: number;
    showTotal: boolean;
}

interface SalesByStoreQueryData {
    fromDate: Date;
    toDate: Date;
    numStores?: number;
}

export interface SongsData {
    HasMore: boolean;
    Data: SongDataItem[];
    Total: number;
}
