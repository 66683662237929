import * as React from "react";

import { PetitionHelper } from "../utils/PetitionHelper";
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, Tooltip, Legend } from "recharts";
import { SalesDataItem } from "../../models/SalesDataItem";
import { Spinner } from "../common/Spinner";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { formatCurrencyNoSymbol } from "../common/Formatter";

interface SalesDataProps {
    onDataRead?: (data: SalesDataItem[]) => void;
}

interface SalesDataState {

    loading: boolean;
    data: SalesDataItem[];
    categories: { [id: number]: string };
    usedCategories: Set<string>;
}


class SalesDataComp extends React.Component<SalesDataProps & WithTranslationProps, SalesDataState> {


    private colors = [
        "#E8C547",
        "#4D5061",
        "#5C80BC",
        "#CDD1C4",
        "#53A2BE",
        "#132E32",
        "#7E5A9B",
        "#04773B",
        "#953553",
        "#BF40BF",
        "#BDB5D5",
        "#00FF40",
        "#dabfdf",
        "#553333",
        "#669fa8"
    ];

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            categories: null,
            usedCategories: new Set<string>()
        };
    }

    async componentDidMount() {

        var dataResponse = await SalesDataService.getSalesData();


        var usedCategories = new Set<string>();

        var data = dataResponse.Data.map((item, index) => {
            var dato = {
                ItemDate: item.ItemDate,
            } as any;

            item.Entries.forEach((entry, index) => {
                var categoryName = dataResponse.Categories[entry.CategoryId];

                dato[categoryName] = entry.Amount
                usedCategories.add(categoryName);
            });

            return dato;
        })

        this.setState({
            loading: false,
            data: data,
            categories: dataResponse.Categories,
            usedCategories: usedCategories
        },
            () => {
                if (this.props.onDataRead) {
                    this.props.onDataRead(dataResponse.Data);
                }
            }
        );

    }

    render() {

        if (this.state.loading) {
            return <Spinner />;
        }
        if (this.state.data == null) {
            return <div>No hay datasources</div>
        }
        const i18n = this.props.i18n;

        return (
            <div className="charts-container">
                <h3>{i18n.t("home:salesPerYear")}</h3>

                <ResponsiveContainer className="mt-2" aspect={1.8} width="100%">
                    <BarChart data={this.state.data}>

                        <XAxis axisLine={false} tickLine={false}
                            dataKey="ItemDate"
                            className="xaxis-ablanco"
                            tickMargin={15}
                        />
                        <YAxis axisLine={false} tickLine={false}
                            tickFormatter={(val, index) => formatCurrencyNoSymbol(i18n.language, val)}
                        />

                        {Object.values(this.state.categories)
                            .filter(cat => this.state.usedCategories.has(cat))
                            .sort((a, b) => a.localeCompare(b))
                            .map((categoryKey, index, array) => {
                                

                                return <Bar barSize={10}
                                    key={index}
                                    width={10}
                                    dataKey={categoryKey}
                                    fill={this.colors[index]}
                                    stackId="x"
                                >
                                </Bar>
                            })}
                        <Legend align="right" verticalAlign="top" iconType="circle" />
                        <Tooltip cursor={{visibility: "hidden"}} formatter={(val) => formatCurrencyNoSymbol(i18n.language, val)} />
                    </BarChart>
                </ResponsiveContainer>

            </div>

        );
    }
}



export const SalesData = withTranslation()(SalesDataComp);


enum GroupByMode {
    Year = 0,
    Month = 1
}

class SalesDataService {

    static async getSalesData(): Promise<SalesDataModel> {

        var today = new Date();
        var model = {
            fromDate: new Date(today.getFullYear() - 10, 12, 31),
            toDate: new Date(today.getFullYear(), 12, 31),
            groupByMode: GroupByMode.Year,
        } as SalesDataQueryModel;

        var result = await PetitionHelper.MakeGetPetitionWithData("Services/Custom/GetSalesData", model) as SalesDataModel;

        result.Data = result.Data.sort((a, b) => a.ItemDate.localeCompare(b.ItemDate));

        return result;
    }

}

interface SalesDataModel {
    Data: SalesDataItem[];

    Categories: any
}


interface SalesDataQueryModel {
    fromDate: Date;
    toDate: Date;

    publicMusicianId: number;

    groupByMode: GroupByMode;
}
