import { DropdownItemModel, DropdownResponse } from "../../models/DropdownItemModel";
import { DropDownBase, IDropDownProps } from "../base/DropDownBase";
import { PetitionHelper } from "../utils/PetitionHelper";

export class CountryDropDown extends DropDownBase<IDropDownProps> {

    protected async GetItems(): Promise<DropdownItemModel[]> {
        
        let result = await PetitionHelper.MakeGetPetition("Services/Custom/GetCountries") as DropdownResponse;
        return result.Items;
    }

};