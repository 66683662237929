import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { ClientContext } from './security/ClientContext';

import './stylesPriv/navbar-fixed-left.css';
import './stylesPriv/private.css';

export class LayoutComp extends Component<WithTranslationProps, any> {

    render() {

        const { i18n } = this.props;

        return (
            <div className="private">
                <NavMenu />
                <Container fluid={true}>
                    <div className="cabecera">
                        <div className="name">
                            <div className="nombre_usuario">
                                <span>{i18n.t("home:hello")},</span> {ClientContext.current.name}
                            </div>
                        </div>
                        <Nav horizontal="end">
                           
                            <NavItem>
                                <select className="form-control-sm mt-4 mr-3" value={this.props.i18n.language} onChange={(evt) => this._languageChange(evt.target.value)}>
                                    <option value="es">Espa&ntilde;ol</option>
                                    <option value="en">English</option>
                                </select>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark mt-3" to="/"
                                    onClick={() => {
                                        ClientContext.logOff();
                                    }}>
                                    <i className="fas fa-sign-out-alt mr-1"></i>
                                    {i18n.t("navMenu:LogOff")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    {this.props.children}
                </Container>
            </div>
        );
    }

    private _languageChange(language: string): void {
        this.props.i18n.changeLanguage(language);
    }
}

export const Layout = withTranslation(["navMenu", "home"], { withRef: true })(LayoutComp);