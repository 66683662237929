import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ModelValidation, validateEmail } from "./utils/ModelValidation";
import { PetitionHelper } from "./utils/PetitionHelper";
import { Spinner } from "./common/Spinner";
import { CountryDropDown } from "./common/CountryDropDown";
import { UserAlertPopup } from "./common/UserAlertPopup";
import { i18n as i18next } from 'i18next';

interface ProfileState {
    showLoading: boolean;
    textForUserMessage: string;
    textForUserError: string;

    email: string;
    password: string;
    passwordConfirmation: string;

    firstName: string;
    lastName: string;

    phone: string;

    countryId: number;
    countryName: string;

    validation: ModelValidation;
    passwordValidation: ModelValidation;
    emailValidation: ModelValidation;

    showChangeEmailWindow: boolean;
    newEmail: string;
    newEmailConfirmation: string;
}

class ProfileComp extends Component<WithTranslationProps & RouteComponentProps, ProfileState> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLoading: false,
            textForUserMessage: null,
            textForUserError: null,

            firstName: "",
            lastName: "",
            email: "",

            phone: "",
            countryId: null,
            countryName: "",

            password: "",
            passwordConfirmation: "",

            validation: new ModelValidation(),
            passwordValidation: new ModelValidation(),
            emailValidation: new ModelValidation(),

            showChangeEmailWindow: false,
            newEmailConfirmation: null,
            newEmail: null,
        };
    }

    async componentDidMount() {
        var data = await ProfileService.LoadData();
        this.setState(data);
    }

    render() {

        const { i18n } = this.props;

        return (
            <div className="align-middle">
                {this.state.showLoading && <Spinner />}

                {this.state.textForUserMessage != null &&
                    <UserAlertPopup
                        title={"Music Traders"}
                        visible={true}
                        onOk={() => this.setState({ textForUserMessage: null })}
                        bodyText={this.state.textForUserMessage}
                        okButtonText={"OK"}
                        danger={false}
                    />
                }
                {this.state.textForUserError != null &&
                    <UserAlertPopup
                        title={"Music Traders"}
                        visible={true}
                        onOk={() => this.setState({ textForUserError: null })}
                        bodyText={this.state.textForUserError}
                        okButtonText={"OK"}
                        danger={true}
                    />
                }

                {this.state.showChangeEmailWindow &&
                    <Modal
                        title={i18n.t("profile:changeEmail")}
                        isOpen={true}
                        onClosed={() => this.setState({ showChangeEmailWindow: false, newEmail: null, newEmailConfirmation: null })}
                        size="lg"
                    >
                        <ModalHeader>{i18n.t("profile:changeEmail")}</ModalHeader>

                        <div style={{ padding: "20px 30px" }}>

                            <Row>
                                <Col sm={12}>
                                    <FormGroup className="border-label">
                                        <Label for="newEmail">{i18n.t("profile:newEmail")}</Label>
                                        <Input type="email" name="newEmail" id="newEmail" value={this.state.newEmail}
                                            onChange={(evt) => this.setState({ newEmail: evt.target.value })}
                                            invalid={this.state.emailValidation.hasError("newEmail")}
                                        />
                                        {this._errorMessage("newEmail", this.state.emailValidation)}

                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <FormGroup className="border-label">
                                        <Label for="newEmailConfirmation">{i18n.t("profile:newEmailConfirmation")}</Label>
                                        <Input type="email" name="newEmailConfirmation" id="newEmailConfirmation"
                                            value={this.state.newEmailConfirmation}
                                            onChange={(evt) => this.setState({ newEmailConfirmation: evt.target.value })}
                                            invalid={this.state.emailValidation.hasError("newEmailConfirmation")}
                                        />
                                        {this._errorMessage("newEmailConfirmation", this.state.emailValidation)}

                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <ModalFooter>
                            <Button className={"btn-primary"} size="lg" onClick={() => this.saveEmail(i18n)}>
                                {i18n.t("profile:save")}
                            </Button>
                            <Button color="secondary" onClick={() => this.setState({ showChangeEmailWindow: false, newEmail: null, newEmailConfirmation: null })}>
                                {i18n.t("profile:cancel")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                }

                <Container>

                    <Form>
                        <Row>
                            <Col sm={12} className="mt-3 mb-3">
                                <h1>{i18n.t("profile:profileTitle")}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup className="border-label">
                                    <Label for="firstName">{i18n.t("profile:firstName")}</Label>
                                    <Input type="text" name="firstName" id="firstName"
                                        value={this.state.firstName}
                                        onChange={(evt) => this.setState({ firstName: evt.target.value })}
                                        placeholder={i18n.t("profile:firstNameInstructions")}
                                        invalid={this.state.validation.hasError("firstName")}
                                    />
                                    {this._errorMessage("firstName", this.state.validation)}

                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup className="border-label">
                                    <Label for="lastName">{i18n.t("profile:lastName")}</Label>
                                    <Input type="text" name="lastName" id="lastName"
                                        value={this.state.lastName}
                                        onChange={(evt) => this.setState({ lastName: evt.target.value })}
                                        placeholder={i18n.t("profile:lastNameInstructions")}
                                        invalid={this.state.validation.hasError("lastName")}
                                    />
                                    {this._errorMessage("lastName", this.state.validation)}

                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup className="border-label">
                                    <Label for="phone">{i18n.t("profile:phone")}</Label>
                                    <Input type="text" name="phone" id="phone"
                                        value={this.state.phone}
                                        onChange={(evt) => this.setState({ phone: evt.target.value })}
                                        placeholder={i18n.t("profile:phoneInstructions")}
                                        invalid={this.state.validation.hasError("phone")}
                                    />
                                    {this._errorMessage("phone", this.state.validation)}

                                </FormGroup>
                            </Col>

                            <Col sm={6}>

                                <CountryDropDown
                                    label={i18n.t("profile:country")}
                                    required={true}
                                    initialText={i18n.t("profile:countryInstructions")}
                                    selectedItemValue={this.state.countryId}
                                    selectedItemText={this.state.countryName}
                                    errorMessage={this.state.validation.getError("country")}
                                    onChanged={(val, text, item) => {
                                        this.setState({
                                            countryId: val,
                                            countryName: text
                                        });
                                    }}
                                />


                            </Col>
                        </Row>

                        <Row>
                            <Col sm={10}>
                                <FormGroup className="border-label">
                                    <Label for="email">{i18n.t("profile:email")}</Label>
                                    <Input readOnly type="email" name="email" id="email"
                                        value={this.state.email}
                                        onChange={(evt) => this.setState({ email: evt.target.value })}
                                        placeholder={i18n.t("profile:emailInstructions")}
                                        invalid={this.state.validation.hasError("email")}
                                    />
                                    {this._errorMessage("email", this.state.validation)}

                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <Button color="secondary" style={{ marginTop: 27 }} onClick={() => this.setState({ showChangeEmailWindow: true })}>
                                    {i18n.t("profile:changeEmail")}
                                </Button>
                            </Col>
                        </Row>

                    </Form>

                    <Row>
                        <Col md={12}>
                            <Button size="lg" color="primary" onClick={() => this.saveProfile(i18n)}>
                                {i18n.t("profile:save")}
                            </Button>



                            <Button color="secondary" className="float-right" onClick={() => this.props.history.push("/")}>
                                {i18n.t("home:backToHome")}
                            </Button>

                            <Button color="secondary" style={{ marginRight: 15 }} className="float-right" onClick={() => this.setState({ showChangeEmailWindow: true })}>
                                {i18n.t("profile:changeEmail")}
                            </Button>
                        </Col>
                    </Row>
                </Container>

                <hr className="separator" />

                <Container>
                    <Row>
                        <Col>
                            <h2>{i18n.t("profile:savePassword")}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup className="border-label">
                                <Label for="password">{i18n.t("profile:password")}</Label>
                                <Input type="password" name="password" id="password"
                                    value={this.state.password}
                                    onChange={(evt) => this.setState({ password: evt.target.value })}
                                    invalid={this.state.passwordValidation.hasError("password")}
                                />
                                {this._errorMessage("password", this.state.passwordValidation)}

                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup className="border-label">
                                <Label for="passwordConfirmation">{i18n.t("profile:passwordConfirm")}</Label>
                                <Input type="password" name="passwordConfirmation" id="passwordConfirmation"
                                    value={this.state.passwordConfirmation}
                                    onChange={(evt) => this.setState({ passwordConfirmation: evt.target.value })}
                                    invalid={this.state.passwordValidation.hasError("passwordConfirmation")}
                                />
                                {this._errorMessage("passwordConfirmation", this.state.passwordValidation)}

                            </FormGroup>


                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button size="lg" color="primary" onClick={() => this.savePassword(i18n)}>
                                {i18n.t("profile:savePassword")}
                            </Button>

                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }

    private async savePassword(i18n: i18next) {

        var validation = await this.validatePassword();
        if (validation.isOk) {

            try {

                var result = await ProfileService.ChangePassword(this.state);

                if (!result.IsOk) {

                    if (result.Errors != null) {
                        var keys = Object.keys(result.Errors);
                        keys.forEach((key, index) => {
                            validation.addError(key, result.Errors[key]);
                        });
                    }


                    this.setState({
                        passwordValidation: validation,
                        textForUserError: i18n.t("profile:error")
                    });
                }
                else {
                    this.setState({
                        passwordValidation: validation,
                        password: null,
                        passwordConfirmation: null,
                        textForUserMessage: i18n.t("profile:passwordChanged")
                    });
                }
            }
            catch (error) {
                alert(error);
            }
        }
        else {
            this.setState({ passwordValidation: validation });
        }
    }


    private async saveEmail(i18n: i18next) {

        var validation = await this.validateEmail();
        this.setState({ emailValidation: validation }, async () => {
            if (validation.isOk) {

                try {

                    var result = await ProfileService.ChangeEmail(this.state);

                    if (!result.IsOk) {

                        if (result.Errors != null) {
                            var keys = Object.keys(result.Errors);
                            keys.forEach((key, index) => {
                                validation.addError(key, result.Errors[key]);
                            });
                        }


                        this.setState({
                            emailValidation: validation,
                            //textForUserError: i18n.t("profile:error")
                        });
                    }
                    else {
                        this.setState({
                            emailValidation: validation,
                            textForUserMessage: i18n.t("profile:emailChanged")
                        });
                    }
                }
                catch (error) {
                    alert(error);
                }
            }

        })
    }

    private async saveProfile(i18n: i18next) {


        var validation = await this.validateForm();
        if (validation.isOk) {

            try {

                var result = await ProfileService.Save(this.state);

                if (!result.IsOk) {

                    if (result.Errors != null) {
                        var keys = Object.keys(result.Errors);
                        keys.forEach((key, index) => {
                            validation.addError(key, result.Errors[key]);
                        });
                    }

                    this.setState({
                        validation: validation,
                        textForUserError: i18n.t("profile:error")
                    });
                }
                else {
                    this.setState({
                        validation: validation,
                        showChangeEmailWindow: false,
                        newEmail: null,
                        newEmailConfirmation: null,
                        textForUserMessage: i18n.t("profile:dataSaved")
                    });
                }
            }
            catch (error) {
                alert(error);
            }
        }
        else {
            this.setState({ validation: validation });
        }
    }

    private _errorMessage(fieldName: string, validator: ModelValidation): JSX.Element {
        var error = validator.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    private async validateForm(): Promise<ModelValidation> {
        var validation = new ModelValidation();

        if ((this.state.firstName || "") === "") {
            validation.addError("firstName", this.props.i18n.t("errors:required"));
        }

        if ((this.state.lastName || "") === "") {
            validation.addError("lastName", this.props.i18n.t("errors:required"));
        }

        if ((this.state.email || "") === "") {
            validation.addError("email", this.props.i18n.t("errors:required"));
        }
        else if (!validateEmail(this.state.email)) {
            validation.addError("email", this.props.i18n.t("errors:emailFormat"));
        }

        if ((this.state.phone || "") === "") {
            validation.addError("phone", this.props.i18n.t("errors:required"));
        }

        if ((this.state.countryId || "") === "") {
            validation.addError("country", this.props.i18n.t("errors:required"));
        }


        return validation;
    }


    private async validatePassword(): Promise<ModelValidation> {
        var validation = new ModelValidation();

        if ((this.state.password || "") === "") {
            validation.addError("password", this.props.i18n.t("errors:required"));
        }
        else if ((this.state.password || "").length < 7 || (this.state.password || "").length > 15) {
            validation.addError("password", this.props.i18n.t("errors:passwordBetween7and15"));
        }

        if ((this.state.passwordConfirmation || "") === "") {
            validation.addError("passwordConfirmation", this.props.i18n.t("errors:required"));
        }

        if ((this.state.password || "") !== "" &&
            (this.state.passwordConfirmation || "") !== "" &&
            this.state.password !== this.state.passwordConfirmation) {
            validation.addError("password", this.props.i18n.t("signUp:errorPasswordMustMatch"));
        }


        return validation;
    }


    private async validateEmail(): Promise<ModelValidation> {
        var validation = new ModelValidation();

        if ((this.state.newEmail || "") === "") {
            validation.addError("newEmail", this.props.i18n.t("errors:required"));
        }
        else if (!validateEmail(this.state.newEmail)) {
            validation.addError("newEmail", this.props.i18n.t("errors:emailFormat"));
        }
        else {
            if (this.state.email === this.state.newEmail) {
                validation.addError("newEmail", this.props.i18n.t("errors:emailMustChange"));
                return validation;
            }
        }


        if ((this.state.newEmailConfirmation || "") === "") {
            validation.addError("newEmailConfirmation", this.props.i18n.t("errors:required"));
        }
        else if (!validateEmail(this.state.newEmailConfirmation)) {
            validation.addError("newEmailConfirmation", this.props.i18n.t("errors:emailFormat"));
        }



        if (validation.isOk && this.state.newEmailConfirmation !== this.state.newEmail) {
            validation.addError("newEmailConfirmation", this.props.i18n.t("profile:emailsMustMatch"));
        }

        return validation;
    }
};

export const MyProfile = withTranslation(["profile", "errors", "home"])(ProfileComp);

class ProfileService {

    static async ChangeEmail(state: Readonly<ProfileState>): Promise<ProfileResponse> {

        var data = {
            Email: state.newEmail,
            EmailConfirmation: state.newEmailConfirmation,
        } as EmailChangeRequest;

        var result = await PetitionHelper.MakePostPetition("Services/Custom/ChangeEmail", data) as PasswordChangeResponse;

        return result;
    }

    static async LoadData(): Promise<ProfileState> {

        var result = await PetitionHelper.MakeGetPetition("Services/Custom/MyProfile");

        var model = {
            firstName: result.FirstName,
            lastName: result.LastName,
            email: result.Email,
            phone: result.Phone,
            countryId: result.CountryId,
            countryName: result.CountryName
        } as ProfileState

        return model;
    }

    static async Save(state: Readonly<ProfileState>): Promise<ProfileResponse> {

        var data = {
            FirstName: state.firstName,
            LastName: state.lastName,
            CountryId: state.countryId,
            Email: state.email,
            Phone: state.phone
        } as ProfileRequest;

        var result = await PetitionHelper.MakePostPetition("Services/Custom/MyProfile", data) as ProfileResponse;

        return result;
    }

    static async ChangePassword(state: Readonly<ProfileState>): Promise<PasswordChangeResponse> {

        var data = {
            Password: state.password,
            PasswordConfirmation: state.passwordConfirmation,
        } as PasswordChangeRequest;

        var result = await PetitionHelper.MakePostPetition("Services/Custom/ChangePassword", data) as PasswordChangeResponse;

        return result;
    }

}

interface PasswordChangeRequest {
    Password: string;
    PasswordConfirmation: string;
}

interface PasswordChangeResponse {
    IsOk: boolean;
    Errors: { [field: string]: string };
}


interface EmailChangeRequest {
    Email: string;
    EmailConfirmation: string;
}

interface EmailChangeResponse {
    IsOk: boolean;
    Errors: { [field: string]: string };
}





interface ProfileRequest {

    FirstName: string;
    LastName: string;

    Email: string;

    Phone: string;

    Password: string;

    CountryId: number;
}


interface ProfileResponse {
    IsOk: boolean;
    Errors: { [field: string]: string };
}



