import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DocumentUpload } from "../common/DocumentUpload";
import { Spinner } from "../common/Spinner";
import { DataSourceDropDown } from "../dataSources/DataSourceDropDown";
import { DataSources } from "../utils/ImagePaths";
import { ModelValidation } from "../utils/ModelValidation";


interface NewImportationProps {
    initialDataSource?: DataSources;
    onCloseFinalDialog: () => void;
}

interface NewImportationState {

    loading: boolean;
    newImportDataSourceId: number;
    newImportValidation: ModelValidation;

    messageToShow: string;
    messageIsError: boolean;
}


export class NewImportationComp extends React.Component<WithTranslationProps & NewImportationProps, NewImportationState> {

    private _fileUploader: DocumentUpload;


    public constructor(props: any) {
        super(props);
        this.state = {
            loading: false,

            newImportDataSourceId: this.props.initialDataSource,
            newImportValidation: new ModelValidation(),

            messageToShow: null,
            messageIsError: false
        };
    }


    render() {

        const { i18n } = this.props;

        return (
            <div>
                {this.state.loading && <Spinner />}

                <DataSourceDropDown
                    label={i18n.t("importations:datasource")}
                    selectedItemText={""}
                    selectedItemValue={this.state.newImportDataSourceId}
                    onChanged={(val) => this.setState({ newImportDataSourceId: val })}
                    disabled={false}
                    errorMessage={this.state.newImportValidation.getError("newImportDataSourceId")}
                    required={true}
                />


                <div className="form-group">
                    <DocumentUpload
                        uploadUrl={"/Services/Custom/Importations/CreateFromFiles"}
                        title={i18n.t("importations:upload")}
                        explicitUpload={true}
                        ref={(uploaderComp) => this._fileUploader = uploaderComp}
                        multiple={true}
                        errorMessage={this.state.newImportValidation.getError("_fileUploader")}
                    />
                </div>
                <Button block size="sm" className="btn-success" onClick={async () => this.validateAndCreateImportations()}>
                    {i18n.t("importations:uploadButton")}
                </Button>

                {this.state.messageToShow != null &&
                    <Modal isOpen={true} color={this.state.messageIsError ? "danger" : "primary"}>
                        <ModalHeader toggle={() => this.setState({ messageToShow: null })}>
                            {i18n.t("importations:createModalTitle")}
                        </ModalHeader>
                        <ModalBody>
                            {this.state.messageIsError &&
                                <React.Fragment>
                                    <p></p>
                                    <ul dangerouslySetInnerHTML={{ __html: this.state.messageToShow }} />
                                </React.Fragment>
                            }

                            {!this.state.messageIsError &&
                                <React.Fragment>
                                    {i18n.t("importations:creationOk")}
                                </React.Fragment>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary"
                                onClick={() => this.setState({ messageToShow: null }, () => this.props.onCloseFinalDialog())}
                            >
                                {i18n.t("importations:dismissModal")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                }
            </div>
        );
    }



    private async validateAndCreateImportations() {

        const { i18n } = this.props;


        const formVal = new ModelValidation();
        if (!this.state.newImportDataSourceId) {
            formVal.addError("newImportDataSourceId", i18n.t("importations:errorDataSource"));
        }
        if (this._fileUploader.getSelectedFiles().length === 0) {
            formVal.addError("_fileUploader", i18n.t("importations:errorFiles"));
        }

        if (!formVal.isOk) {
            this.setState({ newImportValidation: formVal });
            return;
        }

        var data = {
            dataSourceId: this.state.newImportDataSourceId
        };

        this.setState(
            {
                newImportValidation: formVal,
                loading: true
            },
            async () => {

                await this._fileUploader._uploadFiles(data,
                    (result, hasError) => {

                        this._fileUploader.clearFiles();

                        if (hasError) {
                            
                            this.setState({
                                messageIsError: true,
                                loading: false,                                
                                messageToShow: "Error processing the request"
                            });
                        }
                        else {
                            if (result === "ok") {

                                if (!window.location.href.toLowerCase().endsWith("/importations")) {
                                    window.location.href = "/importations";
                                }
                                else {
                                    this.setState({ loading: false })
                                }

                            }
                            else {
                                this.setState({
                                    messageIsError: true,
                                    loading: false,
                                    messageToShow: result
                                });
                            }
                        }
                    });


            });

    }

}

export const NewImportation = withTranslation(["importations"])(NewImportationComp);
