import * as React from "react";
import { SongsDataService, SongsData } from "./SongsDataService";
import { Spinner } from "../common/Spinner";
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Col, NavLink, Pagination, PaginationItem, PaginationLink, Row } from "reactstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { formatCurrency, formatCurrencyNoSymbol } from "../common/Formatter";
import { RegaliasCard } from "../partials/RegaliasCard";

interface SongListProps {
    numSongs?: number;

}

interface SongListState {

    loading: boolean;

    currentPage: number;
    searchResults: SongsData;

    totalSales: number,
    totalSum: number,
    empty: boolean
}


class SongListComp extends React.Component<WithTranslationProps & SongListProps, SongListState> {


    public constructor(props: SongListProps) {
        super(props);
        this.state = {
            loading: true,
            searchResults: null,
            currentPage: 0,
            totalSum: 0,
            totalSales: null,
            empty: false
        };
    }

    async componentDidMount() {

        await this._performSearch(0);
    }

    private async _performSearch(pageNumber: number) {

        this.setState({ loading: true }, async () => {

            let dataResponse = await SongsDataService.getSongsData({
                fromDate: null,
                toDate: new Date(),
                pageNumber: pageNumber,
                pageSize: this.props.numSongs,
                showTotal: true
            });

            this.setState({
                loading: false,
                searchResults: dataResponse,
                currentPage: pageNumber,
                empty: dataResponse.Data.length === 0
            });

        });

    }


    render() {

        const { i18n } = this.props;

        if (this.state.loading) {
            return <Spinner />;
        }

        if (this.state.empty) {
            return <div className="text-center">
                <div dangerouslySetInnerHTML={{ __html: i18n.t("songs:noSongs") }}>

                </div>
                <Row>
                    <Col md={3} xl={4} />
                    <Col md={6} xl={3} className="card-container mt-3 mt-xl-0">

                        <NavLink style={{ padding: 0 }} tag={Link} className="import-source" to="/configDataSources">
                            <RegaliasCard
                                className={"import"}
                                img="/assets/img/icons/import.png"
                                title={i18n.t("home:manualImportInstructions")}
                                subtitle={i18n.t("home:manualImport")}
                            />
                        </NavLink>
                    </Col>
                </Row>
            </div>;
        }

        return (
            <div className="data-table">
                <table className="table table-hover table-sm">
                    <colgroup>
                        <col />
                        <col width={"140"} />
                        <col width={"100"} />
                        <col width={"140"} />
                        <col width={"140"} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{i18n.t("songs:tableTitle")}</th>
                            <th>{i18n.t("songs:tableTotalInUSD")}</th>
                            <th>{i18n.t("songs:tablePercentaje")}</th>
                            <th>{i18n.t("songs:tableFirstSale")}</th>
                            <th>{i18n.t("songs:tableLastSale")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.searchResults.Data.map((song, index) => {

                            var total = this.calculatePercentage(song.TotalInUSD, this.state.searchResults.Total);


                            return <tr key={index}>
                                <td className="song"><Link to={"/songs/" + song.Id}>{song.Title.toLowerCase()}</Link></td>
                                <td>{formatCurrency(i18n.language, song.TotalInUSD)}</td>
                                <td>{formatCurrencyNoSymbol(i18n.language, total)}</td>
                                <td>{song.FirstSale.toLocaleDateString()}</td>
                                <td>{song.LastSale.toLocaleDateString()}</td>
                            </tr>;
                        })}
                    </tbody>
                </table>

                <Pagination>
                    {this.state.currentPage !== 0 &&
                        <PaginationItem>
                            <PaginationLink previous
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    this._performSearch(this.state.currentPage - 1);
                                    return false;
                                }}
                                color="primary"
                                href="#">
                                <FaChevronLeft /> {i18n.t("importations:Previous")}
                            </PaginationLink>
                        </PaginationItem>
                    }

                    {this.state.searchResults.HasMore &&
                        <PaginationItem>
                            <PaginationLink next
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    this._performSearch(this.state.currentPage + 1);
                                    return false;
                                }}
                                color="primary"
                                href="#">

                                {i18n.t("importations:Next")} <FaChevronRight />

                            </PaginationLink>
                        </PaginationItem>
                    }
                </Pagination>
            </div>

        );
    }

    private calculatePercentage(song: number, total: number): number {

        var percentage = ((100 * song) / total);

        return percentage;
    }
}


export const SongList = withTranslation(["songs"])(SongListComp);
