import * as React from "react";

import Dropzone from "react-dropzone";
import { cloneDeep, keys } from "lodash";
import { PetitionHelper } from "../utils/PetitionHelper";
import { FormFeedback } from "reactstrap";

class DocumentUploadProps {
    public onBeforeUpload?: () => boolean;
    public onUpload?: (url: string) => void;
    public onDrop?: (url: string) => void;
    public uploadUrl: string;
    public multiple?: boolean;
    public title?: string;
    public explicitUpload?: boolean;
    public errorMessage?: string;
}

class DocumentUploadState {
    public filesToUpload: File[];
}

export class DocumentUpload extends React.Component<DocumentUploadProps, DocumentUploadState> {

    public constructor(props: DocumentUploadProps) {
        super(props);
        this.state = {
            filesToUpload: []
        };
    }

    public clearFiles() {
        this.setState({ filesToUpload: [] });
    }

    public render(): JSX.Element {

        return (
            <div className="uploader">
                <Dropzone
                    onDrop={acceptedFiles => {
                        let files = this.state.filesToUpload;
                        if (!this.props.multiple) {
                            files = [];
                        }

                        acceptedFiles.forEach((file) => {
                            
                            const reader = new FileReader()

                            reader.onabort = () => console.log('file reading was aborted')
                            reader.onerror = () => console.log('file reading has failed')
                            reader.onload = () => {
                                files.push(file);
                                this.setState({ filesToUpload: files })
                            }
                            reader.readAsArrayBuffer(file);
                        });
                    }}
                    multiple={this.props.multiple}

                >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>{this.props.title || "Drop files here"}</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className={(this.props.errorMessage != null && this.props.errorMessage !== "") ? "is-invalid" : ""}>
                    {this.state.filesToUpload != null && this.state.filesToUpload.map((file, index) => {
                        return <span key={index}>
                            {file.name}
                        </span>;
                    })}
                </div>
                {this.props.errorMessage != null && this.props.errorMessage !== "" &&
                    <FormFeedback>{this.props.errorMessage}</FormFeedback>
                }
            </div>
        );
    }


    public async _uploadFiles(data?: any, finished?: (result: string, isError: boolean) => void): Promise<any> {
        let newState = cloneDeep(this.state);
        newState.totalFileSize = 0;
        newState.bytesUploaded = 0;
        newState.inProgress = true;
        this.setState(newState, async () => {

            let dataToPost = new FormData();

            if (data) {
                let dataKeys = keys(data) as [];
                dataKeys.forEach((key, index) => {
                    dataToPost.append(key, data[key]);
                });
            }

            this.state.filesToUpload.forEach(file => {
                dataToPost.append(file.name, file);
            });

            try {

                let result = await PetitionHelper.MakePostPetitionFile(this.props.uploadUrl, dataToPost);

                if (finished) {
                    finished(result.Result, false);
                }

            } catch (error) {
                finished(null, true);
            }

        });
        return;
    }

    public getSelectedFiles(): File[] {
        return this.state.filesToUpload;
    }
}





