import { ClientContext } from "../security/ClientContext";

export class PetitionHelper {
    

    private static _serverUrl: string;

    public static async getServerUrl(): Promise<string> {

        if (PetitionHelper._serverUrl == null) {
            try {
                var configProm = await fetch("/config.json");
                var config = await configProm.json();

                PetitionHelper._serverUrl = config.url;
            }
            catch {
                PetitionHelper._serverUrl = "https://localhost:44325/";
            }

        }

        return PetitionHelper._serverUrl;

    }


    private static getHeaders() {

        var headers = {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        };


        var token = (ClientContext.current || {}).token;
        if (token != null) {
            headers["Authorization"] = 'bearer ' + token;
        }


        return headers;
    }

    static async MakeGetPetitionWithData(url: string, data: any): Promise<any> {
        var params = this.serialize(data, "");
        return this.MakeGetPetition(url + "?" + params);
    }

    static async Download(url: string, name: string): Promise<any> {
        let urlFinal = (await this.getServerUrl()) + url;

        try {
            let response = await fetch(urlFinal, {
                method: "GET",
                headers: this.getHeaders()
            });
            var blob = await response.blob();

            const blobUrl = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set link's href to point to the Blob URL
            link.href = blobUrl;
            link.download = name;

            // Append link to the body
            document.body.appendChild(link);

            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );

            // Remove link from body
            document.body.removeChild(link);
        }
        catch (error) {
            console.error(error);
            throw error;
        }

    }

    static async MakeGetPetition(url: string): Promise<any> {
        let urlFinal = (await this.getServerUrl()) + url;

        try {
            let response = await fetch(urlFinal, {
                method: "GET",
                headers: this.getHeaders()
            });
            var result = await response.json();

            return result;
        }
        catch (error) {
            console.error(error);
            throw error;
        }

    }


    static async MakePostPetition(url: string, data: any): Promise<any> {
        let urlFinal = (await this.getServerUrl()) + url;

        var bodyAsString = this.serialize(data, "");

        try {


            let response = await fetch(urlFinal, {
                method: "POST",
                headers: this.getHeaders(),
                mode: 'cors',
                body: bodyAsString
            });

            return response.json();
        }
        catch (error) {
            console.error(error);
            throw error;
        }

    }

    static async MakeDeletePetition(url: string) {

        let urlFinal = (await this.getServerUrl()) + url;

        try {

            let response = await fetch(urlFinal, {
                method: "DELETE",
                headers: this.getHeaders(),
                mode: 'cors',
            });

            return response.json();
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async MakePostPetitionFile(url: string, data: FormData): Promise<any> {
        let urlFinal = (await this.getServerUrl()) + url;

        try {

            var headers = this.getHeaders();
            delete headers["Content-Type"];

            let response = await fetch(urlFinal, {
                method: "POST",
                headers: headers,
                //mode: 'cors',
                body: data,               
            });

            if (response.status !== 200) {
                throw response.text;
            }

            return response.json();
        }
        catch (error) {
            console.error(error);
            throw error;
        }

    }

    private static serialize(obj, prefix): string {

        var str = [];
        for (var p in obj) {

            if (obj.hasOwnProperty(p)) {

                let k = prefix ? prefix + "[" + p + "]" : p;
                let v = obj[p];

                var cadena = "";
                if (v == null) {
                    cadena = encodeURIComponent(k) + "=";
                }
                else if (typeof v.getMonth === 'function') {
                    cadena = encodeURIComponent(k) + "=" + encodeURIComponent(v.toISOString());
                }
                else if (v !== null && typeof v === "object") {
                    cadena = this.serialize(v, k);
                }
                else {
                    cadena = encodeURIComponent(k) + "=" + encodeURIComponent(v);
                }
                str.push(cadena);

            }
        }

        return str.join("&");
    }

    public static ParseDate(value: any): Date {
        if (value == null) return null;
        return new Date(parseInt(value.toString().substr(6)));
    }
}