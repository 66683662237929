import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { Container } from 'reactstrap';
import { Footer } from './Footer';

import './stylesPub/public.css';

export class LayoutPublicComp extends Component<WithTranslationProps, any> {

    render() {

        return (
            <Container>

                <section className="public-content">

                    <div style={{ position: 'absolute', top: 5, right: 10 }}>
                        <select className="form-control-sm mt-4 mr-3" id="lngSelect" value={this.props.i18n.language} onChange={(evt) => this._languageChange(evt.target.value)}>
                            <option value="es">Espa&ntilde;ol</option>
                            <option value="en">English</option>
                        </select>
                    </div>

                    {this.props.children}
                </section>
                <Footer />
            </Container>
        );
    }

    private _languageChange(language: string): void {
        this.props.i18n.changeLanguage(language);
    }
}

export const LayoutPublic = withTranslation()(LayoutPublicComp);
