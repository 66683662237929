import { PetitionHelper } from "../utils/PetitionHelper";

export class ImportationService {

    static async getMyImportations(pageNumber: number, pageSize:number, onlyOk: boolean): Promise<ImportationsDataModel> {

        var url = `Services/Custom/Importations/Search?PageNumber=${pageNumber}&pageSize=${pageSize}&onlyOk=${(onlyOk ? "1" : "0")}`;

        var result = await PetitionHelper.MakeGetPetition(url) as ImportationsDataModel;

        result.Data.forEach((dataItem, index) => {
            dataItem.ImportDate = PetitionHelper.ParseDate(dataItem.ImportDate);
        });

        return result;
    }

    static async DownloadImportation(name: string, importationId: number): Promise<any> {

        await PetitionHelper.Download(
            `Services/Custom/Importations/Download?ImportationId=${importationId}`,
            `${name}.csv`
        );

    }

    static GetStateName(state: ImportationState, translator: (string)=>string) : string {
        return translator(`importations:ImportationState_${state}`);
    }

    static async deleteImportation(importationId: number) : Promise<any> {
        var result = await PetitionHelper.MakeDeletePetition(`Services/Custom/Importations/${importationId}`);
        return result;
    }
}

export interface ImportationsDataQueryModel {
    PublicMusicianId: number;
    PageNumber: number;
    onlyOk: boolean
}

export interface ImportationsDataModel {
    HasMore: boolean;
    Data: ImportationsDataItem[];
}



export interface ImportationsDataItem {
    Id: number;
    Name: string;
    ImportDate: Date;
    State: ImportationState;
    DataSource: string;
    TotalSales: number;
    ImportationNotes: string;
}



export enum ImportationState {
    Created = 1,
    Processing = 2,
    Finished = 3,
    Failed = 4,
    Retrying = 5,
}