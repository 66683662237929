
export enum DataSources {

    DistroKid = 1,
    ITunes = 2,
    AppleMusic = 3,
    Rdv = 4,
    SmoothNotes = 5,
    Believe = 6,
    Symphonic = 7,
    InternalFormat = 8,
    BMI = 9,
    ASCAP = 10,
    Ditto = 11,
    SonyMusic = 12,
    IdentityMusic = 13,
    SoundExchange = 14,
    OneRpm = 15,
    TuneCore = 16,
    UniversalMusic = 17,
    WarnerChappell = 18,
    Unison = 19

}

export function getImagePathForDataSource(datasourceId: DataSources) {
    switch (datasourceId) {
        case DataSources.BMI: {
            return "/assets/img/logo_bmi.jpg";
        }
        case DataSources.AppleMusic: {
            return "/assets/img/logo_AppleMusic.png";
        }
        case DataSources.ITunes: {
            return "/assets/img/logo_Itunes.png";
        }
        case DataSources.ASCAP: {
            return "/assets/img/logo_ascap.jpg";
        }
        case DataSources.Unison: {
            return "/assets/img/logo_unison.png";
        }
        case DataSources.Symphonic: {
            return "/assets/img/logo_symphonic.png";
        }
        case DataSources.OneRpm: {
            return "/assets/img/logo_onerpm.png";
        }
        case DataSources.Ditto: {
            return "/assets/img/logo_ditto.png";
        }
        case DataSources.SoundExchange: {
            return "/assets/img/logo_SoundExchange.png";
        }
        case DataSources.WarnerChappell: {
            return "/assets/img/logo_WarnerChappell.jpg";
        }
        case DataSources.TuneCore: {
            return "/assets/img/logo_TuneCore.jpg";
        }
        case DataSources.IdentityMusic: {
            return "/assets/img/logo_identity.png";
        }
        case DataSources.Believe: {
            return "/assets/img/logo_believe.png";
        }
        case DataSources.DistroKid: {
            return "/assets/img/logo_DistroKid.png";
        }
        case DataSources.SmoothNotes: {
            return "/assets/img/logo_SmoothNotes.png";
        }
        case DataSources.Rdv: {
            return "/assets/img/logo_Rdv.png";
        }
        case DataSources.UniversalMusic: {
            return "/assets/img/logo_UniversalMusic.png";
        }
        case DataSources.SonyMusic: {
            return "/assets/img/logo_SonyMusic.png";
        }
        default:
            return "";
    }
}
