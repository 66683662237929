import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormFeedback, FormGroup, Input, Jumbotron, Label, Row } from 'reactstrap';
import { Spinner } from './common/Spinner';

import { ModelValidation } from "./utils/ModelValidation";
import { PetitionHelper } from "./utils/PetitionHelper";

interface ChangePasswordState {

    newPassword: string,
    newPasswordRepeat: string,
    changePasswordMessage: string,
    isLoading: boolean;

    requestCode: string;

    validation: ModelValidation;
}

class ChangePasswordComp extends Component<WithTranslationProps & RouteComponentProps, ChangePasswordState> {

    constructor(props: WithTranslationProps & RouteComponentProps) {
        super(props);
        this.state = {
            newPassword: "",
            newPasswordRepeat: "",
            changePasswordMessage: null,
            isLoading: false,
            validation: new ModelValidation(),
            requestCode: props.match.params["requestCode"]
        };

    }

    render() {

        const { i18n } = this.props;

        if (this.state.requestCode == null) {
            return <span>GARRULER!</span>;
        }

        return (
            <div className="align-middle">
                {this.state.isLoading && <Spinner />}
                <Row>
                    <Col md={5} lg={6}>
                        <Jumbotron>
                            <Container fluid>
                                <h1>{i18n.t("login:mainTitle")}</h1>
                                <p className="lead">{i18n.t("login:mainSubTitle")}</p>
                                <img className="img-fluid d-none d-md-block" src="/assets/img/girl.png" alt="" />
                            </Container>
                        </Jumbotron>
                    </Col>
                    <Col md={7} lg={6} className="form-content">
                        <img className="text-center mb-3 d-none d-md-block" src="/assets/img/logo.png" alt="MusicTraders" />

                        <p className="lead d-none mb-5 d-md-block">{i18n.t("login:changePassword")}</p>
                        <p>{i18n.t("login:changePasswordInstructions")}</p>

                        {this.state.changePasswordMessage != null &&
                            <Alert color="success">
                                {this.state.changePasswordMessage}
                            </Alert>
                        }
                        {this.state.changePasswordMessage == null &&
                            <Form className="login">
                                <Row>
                                    <Col md={12}>
                                        <FormGroup row className="border-label">

                                            <Label for="newPassword">{i18n.t("login:newPassword")}</Label>

                                            <Input type="text" name="newPassword" id="newPassword"
                                                value={this.state.newPassword}
                                                onChange={(evt) => this.setState({ newPassword: evt.target.value })}
                                                invalid={this.state.validation.hasError("newPassword")}
                                            />
                                            {this._errorMessage("newPassword")}

                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup row className="border-label">

                                            <Label for="login">{i18n.t("login:newPasswordRepeat")}</Label>
                                            <Input type="text" name="newPasswordRepeat" id="newPasswordRepeat"
                                                value={this.state.newPasswordRepeat}
                                                onChange={(evt) => this.setState({ newPasswordRepeat: evt.target.value })}
                                                invalid={this.state.validation.hasError("newPasswordRepeat")}
                                            />
                                            {this._errorMessage("newPasswordRepeat")}

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup row>
                                    <Col sm={6}>
                                        <Button onClick={() => this._recoverPassword()}>
                                            {i18n.t("login:setNewPassword")}
                                        </Button>
                                    </Col>
                                    <Col sm={6} className="text-center">
                                        <Button outline color="secondary" block size="lg"
                                            onClick={() => this.props.history.push("/")}>
                                            {i18n.t("home:backToHome")}
                                        </Button>

                                    </Col>
                                </FormGroup>
                            </Form>
                        }

                    </Col>
                </Row>
            </div>

        );
    }
    /*
     

            <div className="align-middle">
                {this.state.isLoading && <Spinner />}
                <Container>
                    <Jumbotron>
                        <Container fluid>
                            <h1 className="display-3">{i18n.t("login:changePassword")}</h1>
                            <p className="lead">{i18n.t("login:changePasswordInstructions")}</p>
                        </Container>
                    </Jumbotron>

                    <Row>
                        <Col lg={3}>
                        </Col>
                        <Col md={12} lg={6}>
                            {this.state.changePasswordMessage != null &&
                                <Alert color="success">
                                    {this.state.changePasswordMessage}
                                </Alert>
                            }
                            {this.state.changePasswordMessage == null &&
                                <Form>
                                    <FormGroup row>
                                        <Col sm={4}>
                                            <Label for="login">{i18n.t("login:newPassword")}</Label>
                                        </Col>
                                        <Col sm={8}>
                                            <Input type="text" name="newPassword" id="newPassword"
                                                value={this.state.newPassword}
                                                onChange={(evt) => this.setState({ newPassword: evt.target.value })}
                                                invalid={this.state.validation.hasError("newPassword")}
                                            />
                                            {this._errorMessage("newPassword")}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={4}>
                                            <Label for="login">{i18n.t("login:newPasswordRepeat")}</Label>
                                        </Col>
                                        <Col sm={8}>
                                            <Input type="text" name="newPasswordRepeat" id="newPasswordRepeat"
                                                value={this.state.newPasswordRepeat}
                                                onChange={(evt) => this.setState({ newPasswordRepeat: evt.target.value })}
                                                invalid={this.state.validation.hasError("newPasswordRepeat")}
                                            />
                                            {this._errorMessage("newPasswordRepeat")}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={6} className="text-center">
                                            <Button onClick={() => this._recoverPassword()}>
                                                {i18n.t("login:recoverPassword")}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            }
                        </Col>

                    </Row>
                </Container>
            </div>
     
     * */

    private async _recoverPassword() {

        let validation = await this.validateForm();
        if (validation.isOk) {

            this.setState({ isLoading: true }, async () => {
                try {

                    var result = await ChangePasswordService.PerformPasswordChange(
                        this.state.newPassword,
                        this.state.requestCode
                    );

                    this.setState(
                        {
                            changePasswordMessage: result.IsOk
                                ? this.props.i18n.t("login:changePasswordSuccess")
                                : result.Message
                            ,
                            isLoading: false
                        }
                    );
                }
                catch (error) {
                    alert(error);
                }
            });

        }
        else {
            this.setState({ validation: validation });
        }
    }

    private _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validation.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    private async validateForm(): Promise<ModelValidation> {
        var validation = new ModelValidation();
        if ((this.state.newPassword || "") === "") {
            validation.addError("newPassword", this.props.i18n.t("errors:required"));
        }
        if ((this.state.newPasswordRepeat || "") === "") {
            validation.addError("newPasswordRepeat", this.props.i18n.t("errors:required"));
        }

        if (validation.isOk) {
            if (this.state.newPassword !== this.state.newPasswordRepeat) {
                validation.addError("newPasswordRepeat", this.props.i18n.t("login:passwordAndConfirmationMustMatch"));
            }
        }


        return validation;
    }
};

export const ChangePassword = withTranslation(["login", "errors", "home"])(ChangePasswordComp);

class ChangePasswordService {

    public static async PerformPasswordChange(password: string,
        requestCode: string): Promise<ChangePasswordPerformResponse> {

        var result = await PetitionHelper.MakePostPetition("Services/Custom/PerformPasswordChange", {
            password: password,
            requestCode: requestCode
        } as ChangePasswordPerformRequest) as ChangePasswordPerformResponse;

        return result;

    }

}

interface ChangePasswordPerformRequest {
    password: string;
    requestCode: string;
}

interface ChangePasswordPerformResponse {
    IsOk: boolean;
    Message: string;
}


