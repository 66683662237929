import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";
import { GenericStep2, Step2Props } from "../GenericWizard/GenericStep2";




class Step2Comp extends React.Component<WithTranslationProps & Partial<StepWizardChildProps> & Step2Props, any> {

    render() {
        return <GenericStep2
            {...this.props}
            logoUrl="/assets/img/logo_onerpm.png"
            serviceUrl="Services/Custom/OneRpmImporter"
        />;
    }

}

export const Step2 = withTranslation()(Step2Comp);

