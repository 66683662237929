import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";
import { PetitionHelper } from "../../utils/PetitionHelper";
import { Spinner } from "../../common/Spinner";

import { ImportationStatus } from "../../../models/ImportationStatus";
import { ImportationProgress } from "../ImportationProgress";

export interface Step2Props {
    username: string,
    password: string,
    securityQuestionAnswer?: string;
    onProcessFinished: (message: string) => void
}

interface Step2InternalProps extends Step2Props {
    logoUrl: string;
    serviceUrl: string;
}



interface Step2State {

    loading: boolean;
    importationStatus: ImportationStatus;
}

class GenericStep2Comp extends React.Component<WithTranslationProps & Partial<StepWizardChildProps> & Step2InternalProps, Step2State> {

    public constructor(props: WithTranslationProps & Partial<StepWizardChildProps> & Step2InternalProps) {
        super(props);

        this.state = {
            loading: false,
            importationStatus: null,
        };

    }

    async componentDidMount() {

        try {

            let requestId = await PetitionHelper.MakePostPetition(
                `${this.props.serviceUrl}/Process`, {
                Username: this.props.username,
                Password: this.props.password,
                SecurityQuestionAnswer: this.props.securityQuestionAnswer
            }) as any;


            let timerId = window.setInterval(
                async () => {
                    let result = await PetitionHelper.MakeGetPetition(`${this.props.serviceUrl}/GetStatus?requestId=${requestId}`) as ImportationStatus;
                    if (result.Finished) {
                        window.clearInterval(timerId);
                    }

                    this.setState({ importationStatus: result });
                },
                2000);
        }

        catch (error) {
            alert(error);
        }
        finally {
            //this.setState({ loading: false, validationOK: true });
        }
    }


    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        const { i18n } = this.props;

        return (
            <div>
                <h3 className='text-center'>{i18n.t("dataSources:Step2")}</h3>


                <ImportationProgress
                    logoUrl={this.props.logoUrl}
                    i18n={this.props.i18n}
                    status={this.state.importationStatus}
                    onClick={() => this.props.onProcessFinished(this.state.importationStatus.Message)}
                />


            </div>
        );
    }

}

export const GenericStep2 = withTranslation(["dataSources"])(GenericStep2Comp);

