import React, { Component } from 'react';
import { SongDetailDataService } from "./SongDetailDataService";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Col, Row } from "reactstrap";
import { SalesByStoreGraph, SalesByYearGraph } from "./SongDetailGraphs";
import { SongDataItem } from "../../../models/SongDataItem";
import { Spinner } from "../../common/Spinner";
import { RegaliasCard } from "../../partials/RegaliasCard";
import { formatCurrency } from '../../common/Formatter';

interface SongsState {
    songData: SongDataItem,
    songId: number,
    loading: boolean
}

export class SongDetailComp extends Component<WithTranslationProps, SongsState>     {
    static displayName = SongDetailComp.name;

    public constructor(props: any) {
        super(props);
        this.state = {
            songId: props.match.params.id,
            songData: null,
            loading: true
        };
    }

    async componentDidMount() {

        let dataResponse = await SongDetailDataService.getSongDetailData(this.state.songId);


        this.setState({
            loading: false,
            songData: dataResponse
        });

    }

    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        const { i18n } = this.props;

        return (
            <React.Fragment>
                {this.state.songData == null && <div>
                    <h1>Error!</h1>
                </div>}
                {this.state.songData != null && <div>
                    <h1 className="mt-5">{this.state.songData.Title}</h1>
                    <Row className="mt-5 mb-5">
                        <Col lg={4}>
                            <RegaliasCard
                                title={i18n.t("songs:firstSale")}
                                subtitle={this.state.songData.FirstSale.toLocaleDateString()}
                            />
                        </Col>
                        <Col lg={4}>
                            <RegaliasCard
                                title={i18n.t("songs:lastSale")}
                                subtitle={this.state.songData.LastSale.toLocaleDateString()}
                            />
                        </Col>
                        <Col lg={4}>
                            <RegaliasCard
                                title={i18n.t("songs:totalSales")}
                                subtitle={formatCurrency(i18n.language, this.state.songData.TotalInUSD)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col lg={5}>
                            <h3 className="mb-3">{i18n.t("songs:salesByYear")}</h3>
                            <SalesByYearGraph data={this.state.songData.SalesByYear} />
                        </Col>
                        <Col lg={7}>
                            <h3 className="mb-3">{i18n.t("songs:salesByStore")}</h3>
                            <SalesByStoreGraph data={this.state.songData.SalesByStore} numStoresToShow={10} />
                        </Col>
                    </Row>
                </div>}
            </React.Fragment>
        );
    }
}

export const SongDetail = withTranslation(["songs"])(SongDetailComp);
