import React, { Component } from 'react';

import { AuthenticatedSection, UnAuthenticatedSection } from "./components/security/AuthenticatedSection";
import { Layout } from './components/Layout';
import { LayoutPublic } from './components/public/LayoutPublic';
import { Home } from './components/Home';
import { Login } from "./components/Login";
import { SignUp } from "./components/SignUp";
import { MyProfile } from "./components/MyProfile";
import { RecoverPassword } from "./components/RecoverPassword";
import { ChangePassword } from "./components/ChangePassword";
import { NotFound } from "./components/NotFound";
import { Songs } from "./components/songs/Index";
import { ImportationsIndex } from "./components/importations/Index";
import { SongDetail } from "./components/songs/detail/Index";
import { ConfigureDataSources } from "./components/configureDataSources/Index";
import { Index as DataSources } from "./components/dataSources/Index";

import { Route, Switch } from 'react-router';

export default class App extends Component {
    static displayName = App.name;

    render() {

        return (
            <React.Fragment>

                <UnAuthenticatedSection>
                    <LayoutPublic>
                        <Switch>
                            <Route exact path='/signup' component={SignUp} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/recover-password' component={RecoverPassword} />
                            <Route exact path='/change-password/:requestCode' component={ChangePassword} />

                            <Route exact path='/' component={Login} />

                            <Route component={NotFound} />

                        </Switch>
                    </LayoutPublic>
                </UnAuthenticatedSection>

                <AuthenticatedSection>

                    <Switch>
                        <Route path="/config" component={ConfigureDataSources} exact />
                        <Route path="/">
                            <Layout>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/my-profile' component={MyProfile} />
                                <Route exact path='/songs' component={Songs} />
                                <Route exact path='/DataSources' component={DataSources} />
                                <Route exact path='/songs/:id' render={(props) => <SongDetail {...props} />} />
                                <Route exact path='/importations' component={ImportationsIndex} />
                                <Route exact path="/configDataSources" component={ConfigureDataSources} />
                            </Layout>

                        </Route>
                    </Switch>
                </AuthenticatedSection>

            </React.Fragment>
        );
    }
}
