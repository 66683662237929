import React, { Component } from 'react';
import { SongList } from "./SongList";
import { ClientContext } from "../security/ClientContext";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Col, Row } from "reactstrap";
import { TopSongsBySales } from "./TopSongsBySales";



export class SongsComp extends Component<WithTranslationProps, any>     {


    render() {
        const { i18n } = this.props;

        return (
            <div>
                <h1>{i18n.t("songs:title")} {ClientContext.current.name}</h1>


                <Row className="mt-5">
                    <Col lg={12}>
                        <TopSongsBySales />
                    </Col>
                </Row>


                <Row className="mt-5">
                    <Col lg={12}>
                        <div className="data-table">
                            <SongList numSongs={50} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export const Songs = withTranslation(["songs"])(SongsComp);
