import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";

import { GenericStep1, Step1Props } from "../GenericWizard/GenericStep1";

class Step1Comp extends React.Component<WithTranslationProps & Partial<StepWizardChildProps> & Step1Props, any> {

    
    render() {
        return <GenericStep1
            {...this.props}
            logoUrl="/assets/img/logo_UniversalMusic.png"
            serviceUrl="Services/Custom/UniversalMusicImporter"
            wrongLoginExplanation={this.props.i18n.t("dataSources:WrongLoginExplanation")}            
        />;
    }
}


export const Step1 = withTranslation(["dataSources"])(Step1Comp);

