import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import StepWizard, { StepWizardChildProps } from "react-step-wizard";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Question } from "../GenericWizard/GenericStep1";

interface TuneCoreWizardProps {
    onChange: () => void
}

interface TuneCoreWizardState {

    loading: boolean;
    username: string;
    password: string;
    message: string;
}

class TuneCoreWizardComp extends React.Component<TuneCoreWizardProps & WithTranslationProps & Partial<StepWizardChildProps>, TuneCoreWizardState> {
    wizard: any;

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            username: '',
            password: '',
            message: ''
        };
    }

    render() {

        return (
            <Modal isOpen={true} size="xl"
                toggle={() => this.props.onChange()}
                onClosed={() => this.props.onChange()}
                className="wizardBMI"
            >
                <ModalHeader toggle={() => this.props.onChange()}>
                    {this.props.i18n.t("dataSources:WizardTitle")}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <StepWizard isLazyMount={true}>
                                <Step1 {...this.props}
                                    onLoginOK={(accounts: Object, username: string, password: string, question: Question, done: () => void) => {
                                        this.setState({ username: username, password: password });
                                        done();
                                    }}
                                />
                                <Step2 {...this.props}
                                    onProcessFinished={(message: string) => {
                                        this.props.onChange();
                                    }}
                                    username={this.state.username}
                                    password={this.state.password}
                                />                                
                            </StepWizard>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>);
    }
}

export const TuneCoreWizard = withTranslation(["dataSources"])(TuneCoreWizardComp);

