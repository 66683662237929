import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";

import { GenericReimport, ReimportCompProps } from "../GenericWizard/GenericReimport";

class ReimportComp extends React.Component<WithTranslationProps & ReimportCompProps, any> {

    render() {
        return <GenericReimport {...this.props}
            logoUrl="/assets/img/logo_OneRpm.png"
            serviceUrl="Services/Custom/OneRpmImporter" />;
    }

}

export const Reimport = withTranslation(["dataSources"])(ReimportComp);

