import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Alert, Col, Row, NavLink } from "reactstrap";

import { SalesData } from "./partials/SalesData";
import { SalesDataItem } from "../models/SalesDataItem";
import { RegaliasCard } from "./partials/RegaliasCard";
import { TopSongs } from "./songs/TopSongs";
import { SalesPeriod } from "./common/SalesPeriodDropDown";
import { SongsSalesByStore } from "./songs/SongsSalesByStore";
import { DataSourceItem, DataSourceService } from "./dataSources/DataSourceService";
import { Spinner } from "./common/Spinner";
import { ImportationsHomeList } from "./importations/ImportationsHomeList";
import { formatCurrency } from "./common/Formatter";
import { NewQuoteButton } from "./quotes/NewQuoteButton";
import { Link } from "react-router-dom";

interface SalesCompState {
    datasources: DataSourceItem[];
    salesData: SalesDataItem[];
    loading: boolean;
    noSourcesConfigured: boolean;
}

class HomeComp extends React.Component<WithTranslationProps, SalesCompState> {

    static displayName = HomeComp.name;

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            salesData: null,
            datasources: null,
            noSourcesConfigured: false,
        };
    }

    async componentDidMount() {

        var dataSources = await DataSourceService.getMyDataSources();

        var noImportations = dataSources.every(data => data.NumImportations === 0);

        this.setState({
            loading: false,
            datasources: dataSources,
            noSourcesConfigured: dataSources.every(data => !data.Configured)
        });


        if (noImportations) {
            window.location.href = "/configDataSources";
        }
    }


    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        const { i18n } = this.props;

        return (
            <div>

                <div className="d-none">
                    <span className="d-block d-sm-none">XS</span>
                    <span className="d-none d-sm-block d-md-none">SM</span>
                    <span className="d-none d-md-block d-lg-none">MD</span>
                    <span className="d-none d-lg-block d-xl-none">LG</span>
                    <span className="d-none d-xl-block">XL</span>
                </div>



                <Row className="mt-3 mb-5">

                    {this.state.noSourcesConfigured &&
                        <Alert color="warning" className="col-12">
                            <Row>
                                <Col md={12} lg={9}>{i18n.t("home:configureAutomaticTest")}</Col>
                                <Col>
                                <Link className="h-100 btn btn-primary block" to="/configDataSources">
                                        {i18n.t("home:configureAutomaticButton")}
                                    </Link>
                                </Col>
                            </Row>
                        </Alert>
                    }


                    <React.Fragment>
                        <Col md={6} xl={3} className="card-container">

                            <RegaliasCard
                                img="/assets/img/icons/crown.png"
                                title={i18n.t("home:cardTotalSales")}
                                subtitle={formatCurrency(i18n.language, this.calculateTotal())}
                            />
                        </Col>
                        <Col md={6} xl={3} className="card-container">
                            <RegaliasCard
                                img="/assets/img/icons/wallet.png"
                                title={i18n.t("home:cardAverageSales")}
                                subtitle={formatCurrency(i18n.language, this.calculateAverage())}
                            />
                        </Col>
                        <Col md={6} xl={3} className="card-container mt-3 mt-xl-0">
                            <RegaliasCard
                                img="/assets/img/icons/moneyBag.png"
                                title={i18n.t("home:cardYTDSales")}
                                subtitle={formatCurrency(i18n.language, this.calculateYTDTotal())}
                            />
                        </Col>
                    </React.Fragment>

                    <Col md={6} xl={3} className="card-container mt-3 mt-xl-0">

                        <NavLink style={{ padding: 0 }} tag={Link} className="import-source" to="/configDataSources">
                            <RegaliasCard
                                className={"import"}
                                img="/assets/img/icons/import.png"
                                title={i18n.t("home:manualImportInstructions")}
                                subtitle={i18n.t("home:manualImport")}
                            />
                        </NavLink>
                    </Col>
                </Row>



                <Row className="mt-5 mb-5 justify-content-center" >
                    <Col lg={7} xl={7}>
                        <SalesData onDataRead={(data) => this.setState({ salesData: data })} />
                    </Col>

                    {this.state.salesData != null &&
                        <Col md={9} lg={5} xl={5} className="mt-5 mt-xl-0">
                            <NewQuoteButton />
                        </Col>
                    }
                </Row>


                <Row className="mt-5 mb-5">
                    <Col lg={6}>
                        <ImportationsHomeList />

                    </Col>
                    <Col lg={6}>
                        <TopSongs salesPeriod={SalesPeriod.LastQuarter} title={i18n.t("home:top5Songs")} numSongs={5} total={this.calculateTotal()} />
                    </Col>
                </Row>


                <Row className="mt-5">
                    <Col lg={12}>
                        <SongsSalesByStore salesPeriod={SalesPeriod.LastQuarter} title={i18n.t("home:top5IncomeSources")} numStoresToShow={5} />
                    </Col>
                </Row>

            </div>
        );
    }

    private calculateTotal(): number {

        if (this.state.salesData == null) return 0;

        var acum = 0;
        (this.state.salesData || []).forEach((data, index) => {

            data.Entries.forEach((saleItem, index2) => {
                acum += saleItem.Amount;
            });

        });

        return acum;
    }

    private calculateAverage(): number {
        var acum = 0;

        var yearsWithData = 0;

        (this.state.salesData || []).forEach((data, index) => {
            var yearAcum = 0;
            data.Entries.forEach((saleItem, index2) => {
                yearAcum += saleItem.Amount;
            });
            if (yearAcum > 1) {
                yearsWithData++;
            }
            acum += yearAcum;
        });

        if (yearsWithData === 0) return 0;

        return acum / yearsWithData;
    }

    private calculateYTDTotal(): number {
        var acum = 0;
        (this.state.salesData || []).filter(data => data.ItemDate === (new Date().getFullYear()).toString())
            .forEach((data, index) => {

                data.Entries.forEach((saleItem, index2) => {
                    acum += saleItem.Amount;
                });

            });

        return acum;
    }
}

export const Home = withTranslation(["home"], { withRef: true })(HomeComp);