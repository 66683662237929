import { DropdownItemModel } from "../../models/DropdownItemModel";

import { InputSelectBase, IDropDownProps } from "../base/InputSelectBase";
import { DataSourceService } from "./DataSourceService";

export class DataSourceDropDown extends InputSelectBase<IDropDownProps> {

    protected async GetItems(): Promise<DropdownItemModel[]> {
        
        let result = await DataSourceService.getAllDatasources();        
        return result;
    }

};