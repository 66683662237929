import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button, Col, NavLink, Pagination, PaginationItem, PaginationLink, Row } from "reactstrap";
import { ImportationsDataModel, ImportationService, ImportationState } from "./ImportationService";
import { Spinner } from "../common/Spinner";
import { FaChevronLeft, FaChevronRight, FaTrashAlt } from 'react-icons/fa';
import { formatCurrencyNoSymbol } from "../common/Formatter";
import { UserConfirmationPopup } from "../common/UserConfirmationPopup";
import { Link } from "react-router-dom";
import { RegaliasCard } from "../partials/RegaliasCard";

interface ImportationsListState {

    loading: boolean;
    currentPage: number;
    searchResults: ImportationsDataModel;
    importationToDelete: number
}


export class ImportationsIndexComp extends React.Component<WithTranslationProps, ImportationsListState> {



    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            currentPage: 0,
            searchResults: null,
            importationToDelete: null
        };
    }

    async componentDidMount() {

        await this._performSearch(0);

        window.setInterval(() => this._performSearch(this.state.currentPage), 5000);
    }

    private async _performSearch(pageNumber: number) {

        var items = await ImportationService.getMyImportations(pageNumber, 10, false);
        this.setState({
            loading: false,
            currentPage: pageNumber,
            searchResults: items,
        });
    }

    render() {

        if (this.state.loading && (this.state.searchResults == null || this.state.searchResults.Data == null)) {
            return <Spinner />;
        }
        const { i18n } = this.props;



        return (
            <div>
                <h1>{i18n.t("importations:Title")}</h1>

                {this.state.loading && <Spinner />}


                {this.state.searchResults.Data.length === 0 && <React.Fragment>
                    <br /><br /><br />
                    <div className="text-center m-t-6">
                        <div dangerouslySetInnerHTML={{ __html: i18n.t("importations:EmptyList") }}>

                        </div>
                        <Row>
                            <Col md={3} xl={4} />
                            <Col md={6} xl={3} className="card-container mt-3 mt-xl-0">

                                <NavLink style={{ padding: 0 }} tag={Link} className="import-source" to="/configDataSources">
                                    <RegaliasCard
                                        className={"import"}
                                        img="/assets/img/icons/import.png"
                                        title={i18n.t("home:manualImportInstructions")}
                                        subtitle={i18n.t("home:manualImport")}
                                    />
                                </NavLink>
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>}

                {this.state.searchResults.Data.length !== 0 && <React.Fragment>

                    <Row className="mt-4 mb-4">
                        <Col md={12}>
                            <div dangerouslySetInnerHTML={{ __html: i18n.t("importations:help") }}></div>
                        </Col>
                    </Row>
                    <div className="data-table">
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>{i18n.t("importations:importDate")}</th>
                                    <th>{i18n.t("importations:dataSource")}</th>
                                    <th>{i18n.t("importations:description")}</th>
                                    <th>{i18n.t("importations:state")}</th>
                                    <th>{i18n.t("importations:totalSales")}</th>
                                    <th>{i18n.t("importations:download")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.searchResults.Data.map((ds, index) => {
                                    var className = "";
                                    if (ds.State === ImportationState.Failed) {
                                        className = "table-danger";
                                    }
                                    return <tr className={className} key={index}>
                                        <td>{ds.ImportDate.toLocaleDateString()}</td>
                                        <td>{ds.DataSource}</td>
                                        <td>
                                            {ds.Name}
                                        </td>
                                        <td>
                                            {ds.State === ImportationState.Failed &&
                                                <span title={ds.ImportationNotes}>
                                                    {ImportationService.GetStateName(ds.State, (cadena) => i18n.t(cadena))}
                                                </span>
                                            }
                                            {ds.State !== ImportationState.Failed &&
                                                <span>{ImportationService.GetStateName(ds.State, (cadena) => i18n.t(cadena))}</span>
                                            }
                                        </td>
                                        <td>{formatCurrencyNoSymbol(i18n.language, ds.TotalSales)}</td>
                                        <td>
                                            <a href="#"
                                                onClick={async (evt) => {
                                                    evt.preventDefault();
                                                    await ImportationService.DownloadImportation(ds.Name, ds.Id);
                                                    return false;
                                                }}
                                            >
                                                {i18n.t("importations:downloadCsv")}
                                            </a>
                                        </td>
                                        <td>
                                            <Button color="link text-danger" onClick={() => this.setState({ importationToDelete: ds.Id })}>
                                                <FaTrashAlt />
                                            </Button>
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination>
                        {this.state.currentPage != 0 &&
                            <PaginationItem>
                                <PaginationLink previous
                                    onClick={(evt) => {
                                        evt.preventDefault();
                                        this._performSearch(this.state.currentPage - 1);
                                        return false;
                                    }}
                                    color="primary"
                                    href="#">
                                    <FaChevronLeft /> {i18n.t("importations:Previous")}
                                </PaginationLink>
                            </PaginationItem>
                        }

                        {this.state.searchResults.HasMore &&
                            <PaginationItem>
                                <PaginationLink next
                                    onClick={(evt) => {
                                        evt.preventDefault();
                                        this._performSearch(this.state.currentPage + 1);
                                        return false;
                                    }}
                                    color="primary"
                                    href="#">

                                    {i18n.t("importations:Next")} <FaChevronRight />

                                </PaginationLink>
                            </PaginationItem>
                        }
                    </Pagination>
                </React.Fragment>}

                {this.state.importationToDelete != null &&

                    <UserConfirmationPopup
                        visible={true}
                        title={i18n.t("importations:deleteModalTitle")}
                        bodyText={i18n.t("importations:deleteModalText")}
                        danger={true}
                        confirmTextButton={i18n.t("dataSources:DeletePopupOk")}
                        cancelTextButton={i18n.t("dataSources:DeletePopupCancel")}
                        onCancel={() => this.setState({ importationToDelete: null })}
                        onConfirm={() => {
                            this._deleteImportation(this.state.importationToDelete)
                        }}
                    />

                }

            </div>
        );
    }

    private async _deleteImportation(importationId: number): Promise<any> {

        this.setState({ loading: true }, async () => {

            var result = await ImportationService.deleteImportation(importationId);
            this.setState({ importationToDelete: null },
                () => this._performSearch(this.state.currentPage)
            );
        });
    }

}

export const ImportationsIndex = withTranslation(["importations", "dataSources"])(ImportationsIndexComp);
