import React, { Component } from 'react';
import { Input, FormFeedback, FormGroup, Label } from 'reactstrap';

import { DropdownItemModel } from "../../models/DropdownItemModel";



export interface IDropDownProps {
    selectedItemValue: number;
    selectedItemText: string;
    label: string;
    required?: boolean;

    onChanged: (value: any) => void;
    errorMessage?: string;
    id?: string;
    disabled?: boolean;
}

export interface IDropDownState {
    dropdownOptions: IDropdownOption[];
    value: string | number;
    text: string;
    label: string;
}

export interface IDropdownOption {
    key: string | number;
    text: string;
    data: any;
}

export abstract class InputSelectBase<IProps extends IDropDownProps> extends Component<IProps, IDropDownState> {

    constructor(props: IProps) {
        super(props);

        var key = this.props.selectedItemValue == null ? 0 : this.props.selectedItemValue;
        var text = this.props.selectedItemText == null ? "N/A" : this.props.selectedItemText;
        const state = {
            dropdownOptions: [
                {
                    key: key,
                    text: text,
                }
            ],
            label: this.props.label,
            value: key,
            text: text
        } as IDropDownState;

        this.state = state;

    }

    protected shouldAddEmptyOption(): boolean {
        return true;
    };

    public async componentDidMount() {
        this._getAndPaintItems();
    }

    protected async _getAndPaintItems() {
        var items = await this.GetItems()
        this.BindItems(items, null);
    }

    protected abstract GetItems(): Promise<DropdownItemModel[]>;

    protected _getOptions(items: DropdownItemModel[]): IDropdownOption[] {

        var options: IDropdownOption[] = [];

        items.forEach((item) => {
            options.push({
                key: item.Id,
                text: item.Name,
                data: item
            } as IDropdownOption);
        });

        if (this.shouldAddEmptyOption() && !options.some((p) => p.key == null)) {
            options.unshift({
                key: null,
                text: null
            } as IDropdownOption);
        }

        return options;
    }

    componentDidUpdate(prevProps, prevState) {
        return true;
    }

    protected BindItems(items: DropdownItemModel[], callback: () => void) {

        var options = this._getOptions(items);

        this.setState({ dropdownOptions: options },
            () => callback == null ? null : callback());

    }

    shouldComponentUpdate(nextProps: IProps, nextState) {
        
        if (this.props.selectedItemValue !== nextProps.selectedItemValue) {
            this.setState({
                text: nextProps.selectedItemText,
                value: nextProps.selectedItemValue
            });
        }
        return true;
    }

    public render(): JSX.Element {
        var value = this.props.selectedItemValue == null ? 0 : this.props.selectedItemValue;

        return this._pintaDropDown(this.state.label, value);
    }

    private _pintaDropDown(label: string, value: any): JSX.Element {

        var className = "";
        if (this.props.required) {
            className = "required";
        }

        return <FormGroup>
            <Label className={className} for={this.props.id} disabled={this.props.disabled}>{this.props.label}</Label>

            <Input type="select"
                value={this.props.selectedItemValue || ""}
                onChange={(evt) => this.props.onChanged(parseInt(evt.target.value))}
                invalid={(this.props.errorMessage != null && this.props.errorMessage.length  > 0)}
            >
                {this.state.dropdownOptions.map((val, index) => {
                    return <option key={index} value={val.key}>{val.text}</option>;
                })}
            </Input>

            {this.props.errorMessage && <FormFeedback>{this.props.errorMessage}</FormFeedback>}
        </FormGroup>;
    }


}
