import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";
import { Step2WithAccounts, Step2Props } from "../GenericWizard/GenericStep2WithAccounts";


class Step2Comp extends React.Component<WithTranslationProps & Partial<StepWizardChildProps> & Step2Props, any> {

    render() {
        return <Step2WithAccounts
            {...this.props}
            logoUrl="/assets/img/logo_Symphonic.png"
            serviceUrl="Services/Custom/SymphonicImporter"
        />;
    }

}

export const Step2 = withTranslation()(Step2Comp);

