import * as React from "react";
import { ModalFooter, Button, Modal, ModalHeader } from "reactstrap";


class UserAlertProps {
    public onOk: () => void;
    public visible: boolean;
    public bodyText: string;
    public title: string;
    public okButtonText: string;
    public danger?: boolean;

}

export class UserAlertPopup extends React.Component<UserAlertProps, any> {

    shouldComponentUpdate() {
        return true;
    }

    public render(): JSX.Element {
        if (!this.props.visible) {
            return null;
        }

        return (
            <Modal
                title={this.props.title}
                isOpen={this.props.visible}
                size="lg"
            >
                {this.props.title &&
                    <ModalHeader className={this.props.danger ? "bg-danger" : null}>{this.props.title}</ModalHeader>
                }
                <div style={{ padding: "20px 30px" }} dangerouslySetInnerHTML={{ __html: this.props.bodyText }}></div>
                <ModalFooter>
                    <Button className={this.props.danger ? "btn-danger" : "btn-primary"} size="lg" onClick={() => this.props.onOk()}>
                        {this.props.okButtonText}
                    </Button>

                </ModalFooter>
            </Modal>
        );
    }
}