import jwt_decode from "jwt-decode";

export class ClientContext {

    private static _current: ClientContext

    public static get current(): ClientContext {

        if (this._current == null) {
            var token = localStorage.getItem("token");
            
            if ((token || "") !== "" && token !== "null") {
                ClientContext.load(token);
            }
        }
        return this._current;
    }


    public static logOff() {

        ClientContext._current = null;
        localStorage.setItem("token", null);
    }

    public static load(token: string) {

        ClientContext._current = ClientContext._current || {} as ClientContext;

        var decoded = jwt_decode(token) as any;
        ClientContext._current.id = decoded.Id;
        ClientContext._current.login = decoded.Login;
        ClientContext._current.name = decoded.FirstName;
        ClientContext._current.lastName = decoded.LastName;
        ClientContext._current.token = token;
        localStorage.setItem("token", token);
    }

    public id: number;
    public login: string;
    public name: string;
    public lastName: string;
    public token: string;
}



