import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { StepWizardChildProps } from "react-step-wizard";
import { PetitionHelper } from "../../utils/PetitionHelper";
import { Spinner } from "../../common/Spinner";

import { ImportationStatus } from "../../../models/ImportationStatus";
import { ImportationProgress } from "../ImportationProgress";

import { FaChevronRight } from "react-icons/fa";

export interface Step2Props {

    accounts: Object,
    username: string,
    password: string,
    onProcessFinished: (message: string) => void
}

interface Step2PropsInternal extends Step2Props {

    logoUrl: string;
    serviceUrl: string;

}

interface Step2State {

    loading: boolean;
    importationStatus: ImportationStatus;
    accounts: Object;
    validationOK: boolean;
    checkedItems: Map<string, boolean>;
}

class Step2WithAccountsComp extends React.Component<WithTranslationProps & Partial<StepWizardChildProps> & Step2PropsInternal, Step2State> {

    public constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            importationStatus: null,
            accounts: {},
            validationOK: true,
            checkedItems: new Map()
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var isChecked = event.target.checked;
        var item = event.target.value;

        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    render() {

        if (this.state.loading) {
            return <Spinner />;
        }

        const { i18n } = this.props;

        return (
            <div>
                <h3 className='text-center'>{i18n.t("dataSources:Step2")}</h3>

                <Row>


                    {!this.state.importationStatus && <React.Fragment>

                        {i18n.t("dataSources:Step2SelectAccountsDescription")}

                        {this.props.accounts != null && <div className="mt-5">

                            <Form>
                                {Object.keys(this.props.accounts).map((key, index) => {
                                    return <Row key={index}>
                                        <Col>
                                            <FormGroup >
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={key}
                                                        onChange={this.handleChange}
                                                    />
                                                    {this.props.accounts[key]}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>;
                                })}
                            </Form>

                        </div>}
                        {!this.state.validationOK && <div className="error-text">{i18n.t("dataSources:CheckRequired")}</div>}

                        <div>
                            <Button color="primary" className="float-right" onClick={() => this._save()}>
                                {i18n.t("dataSources:NextStep")} <FaChevronRight />
                            </Button>
                        </div>
                    </React.Fragment>}

                    <ImportationProgress
                        logoUrl={this.props.logoUrl}
                        i18n={this.props.i18n}
                        status={this.state.importationStatus}
                        onClick={() => this.props.onProcessFinished(this.state.importationStatus.Message)}
                    />

                </Row>
            </div>
        );
    }

    private async _save() {
        var selectedItems = this.getSelectedItems();
        var validationOK = selectedItems.length > 0;
        if (validationOK) {
            try {
                let requestId = await PetitionHelper.MakePostPetition(
                    `${this.props.serviceUrl}/Process`, {
                    Username: this.props.username,
                    Password: this.props.password,
                    SubAccountIds: selectedItems,
                }) as any;
                let timerId = window.setInterval(
                    async () => {
                        let result = await PetitionHelper.MakeGetPetition(`${this.props.serviceUrl}/GetStatus?requestId=${requestId}`) as ImportationStatus;
                        if (result.Finished) {
                            window.clearInterval(timerId);
                        }

                        this.setState({ importationStatus: result });
                    },
                    2000);
            }

            catch (error) {
                alert(error);
            }
            finally {
                //this.setState({ loading: false, validationOK: true });
            }
        }
        else {
            this.setState({ validationOK: false });
        }
    }

    private getSelectedItems() {
        var items = [];

        this.state.checkedItems.forEach((value, key) => {
            if (value) {
                items.push(key);
            }
        })

        return items;
    }
}

export const Step2WithAccounts = withTranslation(["dataSources"])(Step2WithAccountsComp);

