import React, { Component } from 'react';


export class Footer extends Component<any, any> {
    static displayName = Footer.name;

    render() {
        return (
            <section>
                
            </section>
        );
    }
}
