import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Jumbotron, Label, Row } from 'reactstrap';
import { ClientContext } from './security/ClientContext';
import { ModelValidation, validateEmail } from "./utils/ModelValidation";
import { PetitionHelper } from "./utils/PetitionHelper";
import { CountryDropDown } from "./common/CountryDropDown";
import { Spinner } from './common/Spinner';
import { ReCAPTCHA } from "react-google-recaptcha";

interface SignUpState {
    showLoading: boolean;

    email: string;
    password: string;
    passwordConfirmation: string;

    firstName: string;
    lastName: string;

    phone: string;

    countryId: number;
    countryName: string;
    authorization: boolean;

    recaptchaVal: string;

    validation: ModelValidation;
}

class SignUpComp extends Component<WithTranslationProps & RouteComponentProps, SignUpState> {

    private captchaRef: ReCAPTCHA;


    constructor(props: any) {
        super(props);
        this.state = {
            showLoading: false,

            firstName: "",
            lastName: "",
            email: "",

            phone: "",
            countryId: null,
            countryName: "",

            password: "",
            passwordConfirmation: "",

            authorization: false,
            recaptchaVal: null,

            validation: new ModelValidation()
        };
    }

    render() {

        const { i18n } = this.props;

        return (
            <div className="align-middle">
                {this.state.showLoading && <Spinner />}
                <Container>

                    <Row>
                        <Col md={5} lg={6}>
                            <Jumbotron>
                                <Container fluid>
                                    <h1 hidden>{i18n.t("signUp:title")}</h1>
                                    <p className="lead">{i18n.t("signUp:title")}</p>
                                    <img className="img-fluid d-none d-md-block mt-5 mb-5" src="/assets/img/girl.png" alt="" />
                                </Container>
                            </Jumbotron>
                        </Col>

                        <Col md={7} lg={6} className="form-content">

                            <img className="text-center mb-3 d-none d-md-block" src="/assets/img/logo.png" alt="MusicTraders" />
                            <p className="lead d-none mb-5 d-md-block">{i18n.t("signUp:title")}</p>
                            <Form>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup className="border-label">
                                            <Label for="firstName">{i18n.t("signUp:firstName")}</Label>
                                            <Input type="text" name="firstName" id="firstName"
                                                value={this.state.firstName}
                                                onChange={(evt) => this.setState({ firstName: evt.target.value })}
                                                invalid={this.state.validation.hasError("firstName")}
                                            />
                                            {this._errorMessage("firstName")}

                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup className="border-label">
                                            <Label for="lastName">{i18n.t("signUp:lastName")}</Label>
                                            <Input type="text" name="lastName" id="lastName"
                                                value={this.state.lastName}
                                                onChange={(evt) => this.setState({ lastName: evt.target.value })}
                                                invalid={this.state.validation.hasError("lastName")}
                                            />
                                            {this._errorMessage("lastName")}

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <FormGroup className="border-label">
                                            <Label for="phone">{i18n.t("signUp:phone")}</Label>
                                            <Input type="text" name="phone" id="phone"
                                                value={this.state.phone}
                                                onChange={(evt) => this.setState({ phone: evt.target.value })}
                                                invalid={this.state.validation.hasError("phone")}
                                            />
                                            {this._errorMessage("phone")}

                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>

                                        <CountryDropDown
                                            label={i18n.t("signUp:country")}
                                            required={true}
                                            initialText={i18n.t("signUp:countryInstructions")}
                                            selectedItemValue={this.state.countryId}
                                            selectedItemText={this.state.countryName}
                                            errorMessage={this.state.validation.getError("country")}
                                            onChanged={(val, text, item) => {
                                                this.setState({
                                                    countryId: val,
                                                    countryName: text
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <FormGroup className="border-label">
                                            <Label for="email">{i18n.t("signUp:email")}</Label>
                                            <Input type="email" name="email" id="email"
                                                value={this.state.email}
                                                onChange={(evt) => this.setState({ email: evt.target.value })}
                                                invalid={this.state.validation.hasError("email")}
                                            />
                                            {this._errorMessage("email")}

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <FormGroup className="border-label">
                                            <Label for="password">{i18n.t("signUp:password")}</Label>
                                            <Input type="password" name="password" id="password"
                                                value={this.state.password}
                                                onChange={(evt) => this.setState({ password: evt.target.value })}
                                                invalid={this.state.validation.hasError("password")}
                                            />
                                            {this._errorMessage("password")}

                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup className="border-label" >
                                            <Label for="passwordConfirm">{i18n.t("signUp:passwordConfirm")}</Label>
                                            <Input type="password" name="passwordConfirm" id="passwordConfirm"
                                                value={this.state.passwordConfirmation}
                                                onChange={(evt) => this.setState({ passwordConfirmation: evt.target.value })}
                                                invalid={this.state.validation.hasError("passwordConfirmation")}
                                            />
                                            {this._errorMessage("passwordConfirmation")}

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <FormGroup className="pl-4">

                                            <Label check className={this._errorMessage("authorization") != null ? "is-invalid" : ""}>
                                                <Input invalid={this._errorMessage("authorization") != null}
                                                    type="checkbox" id="checkbox"
                                                    checked={this.state.authorization}
                                                    onChange={(evt) => { this.setState({ authorization: evt.target.checked }) }}
                                                />{' '}
                                                {i18n.t("signUp:authorization")}
                                            </Label>

                                            {this._errorMessage("authorization")}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup row className="justify-content-center">

                                    <ReCAPTCHA
                                        sitekey="6Lc_tFIoAAAAAIVE3XIeYXITjczFeh57Ewfzb04Q"
                                        grecaptcha={(window as any).grecaptcha}
                                        ref={(val) => this.captchaRef = val}
                                        theme="dark"
                                        onErrored={(err) => {
                                            debugger;
                                        }}
                                        onChange={(token: string) => {
                                            this.setState({ recaptchaVal: token });
                                        }}
                                    />

                                    {this.state.validation.validationErrors["recaptcha"] != null &&
                                        <div style={{ textAlign: "center", width: "100%", color: "#dc3545" }}>
                                            {this.state.validation.validationErrors["recaptcha"]}
                                        </div>
                                    }

                                </FormGroup>


                                <Row>
                                    <Col md={12} lg={6}>
                                        <Button size="lg" color="primary" onClick={() => this.signUp()}>
                                            {i18n.t("signUp:signUp")}
                                        </Button>
                                    </Col>
                                    <Col sm={6} className="text-center">
                                        <Button outline color="secondary" block size="lg"
                                            onClick={() => this.props.history.push("/")}>
                                            {i18n.t("home:backToHome")}
                                        </Button>

                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }

    private async signUp() {

        var validation = await this.validateForm();
        if (validation.isOk) {

            try {

                var result = await SignUpService.SignUp(this.state);

                if (!result.IsOk) {

                    var errors = result.Errors;
                    var keys = Object.keys(errors);
                    keys.forEach((key, index) => {
                        validation.addError(key, errors[key]);
                    });

                    this.setState({ validation: validation });
                }
                else {
                    ClientContext.load(result.Token);
                    this.props.history.push("/");
                }
            }
            catch (error) {
                alert(error);
            }
        }
        else {
            this.setState({ validation: validation });
        }
    }

    private _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validation.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    private async validateForm(): Promise<ModelValidation> {
        var validation = new ModelValidation();

        if ((this.state.firstName || "") === "") {
            validation.addError("firstName", this.props.i18n.t("errors:required"));
        }

        if ((this.state.lastName || "") === "") {
            validation.addError("lastName", this.props.i18n.t("errors:required"));
        }

        if ((this.state.email || "") === "") {
            validation.addError("email", this.props.i18n.t("errors:required"));
        }
        else if (!validateEmail(this.state.email)) {
            validation.addError("email", this.props.i18n.t("errors:emailFormat"));
        }


        if ((this.state.phone || "") === "") {
            validation.addError("phone", this.props.i18n.t("errors:required"));
        }

        if ((this.state.countryId || "") === "") {
            validation.addError("country", this.props.i18n.t("errors:required"));
        }

        if ((this.state.password || "") === "") {
            validation.addError("password", this.props.i18n.t("errors:required"));
        }
        else if ((this.state.password || "").length < 7 || (this.state.password || "").length > 15) {
            validation.addError("password", this.props.i18n.t("errors:passwordBetween7and15"));
        }


        if ((this.state.passwordConfirmation || "") === "") {
            validation.addError("passwordConfirmation", this.props.i18n.t("errors:required"));
        }

        if ((this.state.password || "") !== "" &&
            (this.state.passwordConfirmation || "") !== "" &&
            this.state.password !== this.state.passwordConfirmation) {
            validation.addError("password", this.props.i18n.t("signUp:errorPasswordMustMatch"));
        }

        if (!this.state.authorization) {
            validation.addError("authorization", this.props.i18n.t("signUp:authorizationRequired"));
        }

        if ((this.state.recaptchaVal || "") === "") {
            validation.addError("recaptcha", this.props.i18n.t("errors:selectCaptcha"));
        }

        return validation;
    }

};

export const SignUp = withTranslation(["signUp", "errors", "home"])(SignUpComp);

class SignUpService {

    static async SignUp(state: Readonly<SignUpState>): Promise<SignUpResponse> {

        var data = {
            FirstName: state.firstName,
            LastName: state.lastName,
            CountryId: state.countryId,
            Email: state.email,
            Password: state.password,
            Phone: state.phone,
            RecaptchaVal: state.recaptchaVal
        } as SignUpRequest;

        var result = await PetitionHelper.MakePostPetition("Services/Custom/SignUp", data) as SignUpResponse;

        return result;
    }


}


interface SignUpRequest {

    FirstName: string;
    LastName: string;

    Email: string;

    Phone: string;

    Password: string;

    CountryId: number;
    RecaptchaVal: string;
}


interface SignUpResponse {
    IsOk: boolean;
    Errors: { [field: string]: string };
    Token: string;
}



