import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from "react-i18next";
import { DataSourcesAutoConfig } from '../dataSources/DataSourcesAutoConfig';

interface ConfigureDataSourcesState {

    datasourceEditId: number;
}

class ConfigureDataSourcesComp extends Component<WithTranslationProps, ConfigureDataSourcesState>     {

    public constructor(props: any) {
        super(props);

        this.state = {
            datasourceEditId: null
        };
    }

    render() {

        const { i18n } = this.props;

        return (
            <div className="mt-5 mb-5 col-12">

                <h3>{i18n.t("dataSourcesAutoConfig:configurationIntroText")}</h3>

                <p className="mt-5 lead">
                    {i18n.t("dataSourcesAutoConfig:autoDataSourcesText")}
                </p>

                <div className="pl-5 pr-5 ">
                    <DataSourcesAutoConfig />
                </div>

            </div>
        );
    }


}

export const ConfigureDataSources = withTranslation(["home", "dataSourcesAutoConfig"])(ConfigureDataSourcesComp);
