import React, { Component } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormFeedback, FormGroup, Input, Jumbotron, Label, Row } from 'reactstrap';
import { Spinner } from './common/Spinner';
import { ModelValidation } from "./utils/ModelValidation";
import { PetitionHelper } from "./utils/PetitionHelper";
import { ReCAPTCHA } from "react-google-recaptcha";
interface RecoverPasswordState {

    userName: string;
    recaptchaVal: string;
    mailSentMessage: string;
    showLoading: boolean;
    validation: ModelValidation;
}

class RecoverPasswordComp extends Component<WithTranslationProps & RouteComponentProps, RecoverPasswordState> {

    private captchaRef: ReCAPTCHA;

    constructor(props: any) {
        super(props);
        this.state = {
            userName: "",
            mailSentMessage: null,
            showLoading: false,
            recaptchaVal: null,
            validation: new ModelValidation()
        };
    }

    render() {

        const { i18n } = this.props;

        return (

            <div className="align-middle">
                {this.state.showLoading && <Spinner />}
                <Row>
                    <Col md={5} lg={6}>
                        <Jumbotron>
                            <Container fluid>
                                <h1>{i18n.t("login:mainTitle")}</h1>
                                <p className="lead">{i18n.t("login:mainSubTitle")}</p>
                                <img className="img-fluid d-none d-md-block" src="/assets/img/girl.png" alt="" />
                            </Container>
                        </Jumbotron>
                    </Col>
                    <Col md={7} lg={6} className="form-content">

                        <img className="text-center mb-3 d-none d-md-block" src="/assets/img/logo.png" alt="MusicTraders" />
                        <p className="lead d-none mb-5 d-md-block">{i18n.t("login:recoverPassword")}</p>
                        <p>{i18n.t("login:recoverPasswordInstructions")}</p>

                        {this.state.mailSentMessage != null &&
                            <Alert color="success">
                                {this.state.mailSentMessage}
                            </Alert>
                        }
                        {this.state.mailSentMessage == null &&

                            <Form className="login">
                                <Row>
                                    <Col md={12}>
                                        <FormGroup row className="border-label">

                                            <Label for="login">{i18n.t("login:username")}</Label>
                                            <Input type="text" name="login" id="login"
                                                value={this.state.userName}
                                                onChange={(evt) => this.setState({ userName: evt.target.value })}
                                                invalid={this.state.validation.hasError("userName")}
                                            />
                                            {this._errorMessage("userName")}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup row className="justify-content-center">

                                    <ReCAPTCHA
                                        sitekey="6Lc_tFIoAAAAAIVE3XIeYXITjczFeh57Ewfzb04Q"
                                        grecaptcha={(window as any).grecaptcha}
                                        ref={(val) => this.captchaRef = val}
                                        theme="dark"
                                        onErrored={(err) => {
                                            debugger;
                                        }}
                                        onChange={(token: string) => {
                                            this.setState({ recaptchaVal: token });
                                        }}
                                    />

                                    {this.state.validation.validationErrors["recaptcha"] != null &&
                                        <div style={{ textAlign: "center", width: "100%", color: "#dc3545" }}>
                                            {this.state.validation.validationErrors["recaptcha"]}
                                        </div>
                                    }

                                </FormGroup>

                                <FormGroup row>
                                    <Col sm={6}>
                                        <Button color="primary" onClick={() => this._recoverPassword()}>
                                            {i18n.t("login:recoverPassword")}
                                        </Button>
                                    </Col>
                                    <Col sm={6} className="text-center">
                                        <Button outline color="secondary" block size="lg"
                                            onClick={() => this.props.history.push("/")}>
                                            {i18n.t("home:backToHome")}
                                        </Button>

                                    </Col>
                                </FormGroup>
                            </Form>
                        }

                    </Col>
                </Row>
            </div>
        );
    }

    private async _recoverPassword() {

        let validation = await this.validateForm();
        if (validation.isOk) {

            this.setState({ showLoading: true }, async () => {

                try {

                    await RecoverService.InitiatePasswordChange(this.state.userName, this.state.recaptchaVal);
                    this.setState({
                        mailSentMessage: this.props.i18n.t("login:recoverPasswordEmailSent"),
                        showLoading: false
                    });

                }
                catch (error) {
                    alert(error);
                }
            });
        }
        else {
            this.setState({ validation: validation });
        }
    }

    private _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validation.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    private async validateForm(): Promise<ModelValidation> {
        var validation = new ModelValidation();
        if ((this.state.userName || "") === "") {
            validation.addError("userName", this.props.i18n.t("errors:required"));
        }

        if ((this.state.recaptchaVal || "") === "") {
            validation.addError("recaptcha", this.props.i18n.t("errors:selectCaptcha"));
        }

        return validation;
    }
};

export const RecoverPassword = withTranslation(["login", "errors", "home"])(RecoverPasswordComp);

class RecoverService {

    public static async InitiatePasswordChange(login: string, recaptchaVal:string): Promise<any> {

        var model = {
            login: login,
            recaptchaVal: recaptchaVal
        };

        var result = await PetitionHelper.MakePostPetition("Services/Custom/InitiatePasswordChange", model);


        return result;

    }

}


