import React from 'react';
import { Card, CardBody, CardImg, CardSubtitle, CardTitle } from 'reactstrap';


interface RegaliasCardProps {
    title: string;
    subtitle: string;
    img?: string;
    className?: string;
}


export const RegaliasCard = (props: RegaliasCardProps) => <Card className={(props.className || "") + " home"}>

    <CardBody>
        {props.img != null &&
            <CardImg src={props.img} />
        }
        <div>
            <CardSubtitle>{props.subtitle}</CardSubtitle>
            <CardTitle>{props.title}</CardTitle>
        </div>
    </CardBody>
</Card>
