import * as React from "react";
import { withRouter } from "react-router";
import { ClientContext } from "./ClientContext";

const AuthenticatedSectionFn = function (props: any) {
    
    if (ClientContext.current != null) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return null;//<React.Fragment>Aut</React.Fragment>;
}
export const AuthenticatedSection = withRouter(AuthenticatedSectionFn);


export const UnAuthenticatedSectionFn = function (props: any) {
    
    if (ClientContext.current == null) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <React.Fragment></React.Fragment>;

}
export const UnAuthenticatedSection = withRouter(UnAuthenticatedSectionFn);