import * as React from "react";
import { ModalFooter, Button, Modal, ModalHeader } from "reactstrap";


class UserConfirmationProps {
    public onConfirm: () => void;
    public onCancel: () => void;
    public visible: boolean;
    public bodyText: string;
    public title: string;
    public confirmTextButton: string;
    public cancelTextButton: string;
    public danger?: boolean;

}

export class UserConfirmationPopup extends React.Component<UserConfirmationProps, any> {

    shouldComponentUpdate() {
        return true;
    }

    public render(): JSX.Element {
        if (!this.props.visible) {
            return null;
        }

        return (
            <Modal
                title={this.props.title}
                isOpen={this.props.visible}
                onClosed={() => this.props.onCancel()}
                size="lg"
            >
                {this.props.title &&
                    <ModalHeader className={this.props.danger ? "bg-danger" : null}>{this.props.title}</ModalHeader>
                }
                <div style={{ padding: "20px 30px" }} dangerouslySetInnerHTML={{ __html: this.props.bodyText }}></div>
                <ModalFooter>
                    <Button className={this.props.danger ? "btn-danger" : "btn-primary"} size="lg" onClick={() => this.props.onConfirm()}>
                        {this.props.confirmTextButton}
                    </Button>
                    <Button color="secondary" onClick={() => this.props.onCancel()}>
                        {this.props.cancelTextButton}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}