import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Row, Col, Button, Card, CardBody, CardImg, ModalBody, Modal, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { DataSourceItem, DataSourceService } from "./DataSourceService";
import { Spinner } from "../common/Spinner";
import { BMIWizard } from "./BMIWizard/Wizard";
import { AscapWizard } from "./AscapWizard/Wizard";
import { UnisonWizard } from "./UnisonWizard/Wizard";
import { SymphonicWizard } from "./SymphonicWizard/Wizard";
import { OneRpmWizard } from "./OneRpmWizard/Wizard";
import { DittoWizard } from "./DittoWizard/Wizard";
import { SoundExchangeWizard } from "./SoundExchangeWizard/Wizard";
import { WarnerChappellWizard } from "./WarnerChappellWizard/Wizard";
import { TuneCoreWizard } from "./TuneCoreWizard/Wizard";
import { IdentityMusicWizard } from "./IdentityMusicWizard/Wizard";
import { UniversalMusicWizard } from "./UniversalMusicWizard/Wizard";

import { FaRedo, FaUpload } from "react-icons/fa";
import { DataSources, getImagePathForDataSource } from "../utils/ImagePaths";

import { Reimport as BMIReimport } from "./BMIWizard/Reimport";
import { Reimport as AscapReimport } from "./AscapWizard/Reimport";
import { Reimport as UnisonReimport } from "./UnisonWizard/Reimport";
import { Reimport as SymphonicReimport } from "./SymphonicWizard/Reimport";
import { Reimport as OneRpmReimport } from "./OneRpmWizard/Reimport";
import { Reimport as DittoReimport } from "./DittoWizard/Reimport";
import { Reimport as SoundExchangeReimport } from "./SoundExchangeWizard/Reimport";
import { Reimport as WarnerChappellReimport } from "./WarnerChappellWizard/Reimport";
import { Reimport as TuneCoreReimport } from "./TuneCoreWizard/Reimport";
import { Reimport as IdentityMusicReimport } from "./IdentityMusicWizard/Reimport";
import { Reimport as UniversalMusicReimport } from "./UniversalMusicWizard/Reimport";
import { NewImportation } from "../importations/NewImportation";


interface DataSourcesAutoConfigState {

    loading: boolean;
    datasources: DataSourceItem[];
    dataSourceEditId: number;
    refreshDataSource: DataSources;

    uploadFileDataSource: DataSources;
}
interface DataSourcesAutoConfigProps {

}


export class DataSourcesAutoConfigComp extends React.Component<WithTranslationProps & DataSourcesAutoConfigProps, DataSourcesAutoConfigState> {

    reimporterRef: React.RefObject<any>;

    public constructor(props: WithTranslationProps) {
        super(props);
        this.state = {
            loading: true,
            datasources: null,
            dataSourceEditId: null,
            refreshDataSource: null,
            uploadFileDataSource: null
        };

        this.reimporterRef = React.createRef();
    }

    async componentDidMount() {

        await this._performSearch();
    }

    private async _performSearch() {
        
        var items = await DataSourceService.getConfigurableDataSources();
        this.setState({
            loading: false,
            datasources: items,
            refreshDataSource: null,
            dataSourceEditId: null
        });
    }

    render() {

        if (this.state.loading) {
            return <Spinner />;
        }
        const { i18n } = this.props;

        return <Row className="justify-content-center">
            {this.state.datasources.map((ds, index) => {

                return <Col key={index} md={6} lg={3} xl={2}>
                    <Card className="data-source mt-3">
                        <UncontrolledTooltip placement="top" target={`ds${ds.DataSourceId}`} fade={false} delay={0} >
                            {ds.DataSource}
                        </UncontrolledTooltip>
                        <CardImg id={`ds${ds.DataSourceId}`} top width="100%" src={getImagePathForDataSource(ds.DataSourceId)} alt={ds.DataSource} title={ds.DataSource} />

                        <CardBody className="pr-0 pl-0 pb-0 pt-0">

                            {ds.AutomaticImportation && !ds.Configured &&
                                <React.Fragment>
                                    <Button block color="danger"
                                        onClick={() => this.setState({ dataSourceEditId: ds.DataSourceId })}
                                    >
                                        {i18n.t("dataSourcesAutoConfig:Pending")}
                                    </Button>
                                </React.Fragment>
                            }

                            {ds.AutomaticImportation && ds.Configured &&
                                <React.Fragment>
                                    <Button block color="success"
                                        onClick={() => this.setState({ refreshDataSource: ds.DataSourceId })}
                                    >
                                        <FaRedo />
                                        {ds.LastImportation != null &&
                                            <span>{i18n.t("dataSourcesAutoConfig:Refresh")}</span>
                                        }
                                        {ds.LastImportation == null &&
                                            <span>{i18n.t("dataSourcesAutoConfig:Import")}</span>
                                        }
                                    </Button>
                                </React.Fragment>
                            }

                            <Button block color="secondary"
                                onClick={() => this.setState({ uploadFileDataSource: ds.DataSourceId })}
                            >
                                <FaUpload />
                                <span>{i18n.t("dataSourcesAutoConfig:UploadFile")}</span>
                            </Button>

                            {ds.LastImportation != null &&
                                <div className="text-center pt-1 pb-1">
                                    {i18n.t("dataSourcesAutoConfig:LastImportation")}{' '}
                                    <strong>{ds.LastImportation.toLocaleDateString()}</strong>

                                </div>

                            }
                        </CardBody>
                    </Card>


                </Col>
            })
            }

            {this.state.refreshDataSource === DataSources.BMI &&
                <BMIReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.ASCAP &&
                <AscapReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.Unison &&
                <UnisonReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.Symphonic &&
                <SymphonicReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.OneRpm &&
                <OneRpmReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.Ditto &&
                <DittoReimport onProcessFinished={() => this._performSearch()} />
            }

            {this.state.refreshDataSource === DataSources.SoundExchange &&
                <SoundExchangeReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.WarnerChappell &&
                <WarnerChappellReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.TuneCore &&
                <TuneCoreReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.IdentityMusic &&
                <IdentityMusicReimport onProcessFinished={() => this._performSearch()} />
            }
            {this.state.refreshDataSource === DataSources.UniversalMusic &&
                <UniversalMusicReimport onProcessFinished={() => this._performSearch()} />
            }
            {this._paintWizard()}

            {this.state.uploadFileDataSource != null &&
                <Modal isOpen={true} color="primary"
                    toggle={() => this.setState({ uploadFileDataSource: null })}
                    keyboard={true} centered={true} >
                    <ModalHeader toggle={() => this.setState({ uploadFileDataSource: null })}>
                        {i18n.t("importations:createModalTitle")}
                    </ModalHeader>
                    <ModalBody>
                        <NewImportation
                            initialDataSource={this.state.uploadFileDataSource}
                            onCloseFinalDialog={() => {
                                debugger;
                                //window.location.href = "/"
                            }}
                        />

                    </ModalBody>
                </Modal>
            }

        </Row >;
    }

    private _paintWizard(): JSX.Element {

        switch (this.state.dataSourceEditId) {
            case DataSources.BMI: {
                return <BMIWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.ASCAP: {
                return <AscapWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.Unison: {
                return <UnisonWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.Symphonic: {
                return <SymphonicWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.OneRpm: {
                return <OneRpmWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.Ditto: {
                return <DittoWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.SoundExchange: {
                return <SoundExchangeWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.WarnerChappell: {
                return <WarnerChappellWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.TuneCore: {
                return <TuneCoreWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.IdentityMusic: {
                return <IdentityMusicWizard onChange={() => this._performSearch()} />;
            }
            case DataSources.UniversalMusic: {
                return <UniversalMusicWizard onChange={() => this._performSearch()} />;
            }
        }
        return null;
    }


}

export const DataSourcesAutoConfig = withTranslation()(DataSourcesAutoConfigComp);
