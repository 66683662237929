import { DropdownItemModel, DropdownResponse } from "../../models/DropdownItemModel";
import { PetitionHelper } from "../utils/PetitionHelper";

export class DataSourceService {

    static async getMyDataSources(): Promise<DataSourceItem[]> {

        var result = await PetitionHelper.MakeGetPetition("Services/Custom/Datasources/MyDatasources") as DataSourceItem[];

        result.forEach(item => {
            item.LastImportation = PetitionHelper.ParseDate(item.LastImportation);
        });

        return result;
    }

    static async getConfigurableDataSources(): Promise<DataSourceItem[]> {

        var result = await PetitionHelper.MakeGetPetition("Services/Custom/Datasources/MyDatasources") as DataSourceItem[];

        result.forEach((item, index) => {
            item.LastImportation = PetitionHelper.ParseDate(item.LastImportation);
        });

        return result;
    }

    static async getDataSourceModel(dataSourceId: number): Promise<MusicianDataSourceModel> {

        var url = `Services/Custom/Datasources/GetDataSource?dataSourceId=${dataSourceId}` ;

        var result = await PetitionHelper.MakeGetPetition(url) as MusicianDataSourceModel;
        
        return result;
    }

    static async deleteDataSource(dataSourceId: number): Promise<MusicianDataSourceModel> {

        var url = `Services/Custom/Datasources/${dataSourceId}`;

        var result = await PetitionHelper.MakeDeletePetition(url) as any;

        return result;
    }

    static async persist(model: MusicianDataSourceModel): Promise<MusicianDataSourcePersistResponse> {

        var url = `Services/Custom/Datasources/PersistDataSource`;

        var result = await PetitionHelper.MakePostPetition(url, model) as MusicianDataSourcePersistResponse;

        return result;
    }

    static async getAllDatasources(): Promise<DropdownItemModel[]> {
        var url = `Services/Custom/Datasources/GetAllDatasources`;

        var result = await PetitionHelper.MakeGetPetition(url) as DropdownResponse;

        return result.Items;
    }

}

export interface DataSourceItem {
    Id: number;
    DataSourceId: number;
    DataSource: string;
    LogoUrl: string;
    Configured: boolean;
    LastImportation: Date;
    TotalInUSD: number;
    NumImportations: number;
    AutomaticImportation: boolean;
}

export interface MusicianDataSourceModel {
    Id: number;
    DataSourceId: number;
    DataSource: string;
    LogoUrl: string;
    Configured: boolean;
    UserName: string;
    Password: string;   
}

interface MusicianDataSourcePersistResponse {
    IsOk: boolean;
    Errors: { [field: string]: string };
}