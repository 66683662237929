import { PetitionHelper } from "../../utils/PetitionHelper";
import { SongDataItem } from "../../../models/SongDataItem";

export class SongDetailDataService {

    static async getSongDetailData(songId: number): Promise<SongDataItem> {

        var today = new Date();
        var model = {
            fromDate: new Date(today.getFullYear() - 10, 12, 31),
            toDate: new Date(today.getFullYear(), 12, 31),
            songId: songId
        } as SongDetailSalesQueryModel;

        var result = await PetitionHelper.MakeGetPetitionWithData("Services/Custom/GetSongDetailSalesData", model) as SongDataItem;
        result.FirstSale = PetitionHelper.ParseDate(result.FirstSale);
        result.LastSale = PetitionHelper.ParseDate(result.LastSale);

        return result;
    }

}

interface SongDetailSalesQueryModel {
    fromDate: Date;
    toDate: Date;
    songId: number;
    publicMusicianId: number;
}