import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { StepWizardChildProps } from "react-step-wizard";
import { ModelValidation } from "../../utils/ModelValidation";
import { cloneDeep } from "lodash";
import { PetitionHelper } from "../../utils/PetitionHelper";
import { Spinner } from "../../common/Spinner";
import { FaChevronRight } from "react-icons/fa";
import { Step1Props } from "../GenericWizard/GenericStep1";
import { FormEvent } from "react";



export interface Step2InternalProps {
    logoUrl: string;
    serviceUrl: string;

    username: string;
    password: string;
    securityQuestion: string;
}

interface Step2State {

    loading: boolean;
    validation: ModelValidation;
    securityQuestionAnswer: string;

}

class Step2Comp extends React.Component<WithTranslationProps & Partial<StepWizardChildProps> & Step2InternalProps & Step1Props, Step2State> {

    public constructor(props: WithTranslationProps & Partial<StepWizardChildProps> & Step2InternalProps & Step1Props) {
        super(props);

        this.state = {
            loading: false,
            validation: new ModelValidation(),
            securityQuestionAnswer: "",
        };
    }

    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        const { i18n } = this.props;

        return (
            <div>
                <h3 className='text-center'>{i18n.t("dataSources:Step1")}</h3>
                <Row>
                    <Col md={3}>
                        <img className="me-3 logo mx-auto" src={this.props.logoUrl} alt="" />
                    </Col>
                    <Col md={9}>
                        {i18n.t("dataSources:StepSecurityDescription")}

                        <Form onSubmit={(evt: FormEvent) => {
                                evt.preventDefault();
                                this._login()
                                return false;
                            }}
                        >
                            <Row className="mt-5">
                                <Col md={12} lg={6}>
                                    <FormGroup>
                                        <Label for="securityQuestion">{this.props.securityQuestion}</Label>
                                        <Input type="text" name="securityQuestionAnswer" id="securityQuestionAnswer"
                                            value={this.state.securityQuestionAnswer}
                                            onChange={(evt) => {
                                                var newState = cloneDeep(this.state);
                                                newState.securityQuestionAnswer = evt.target.value;
                                                this.setState(newState);
                                            }}
                                            //placeholder={i18n.t("dataSources:UserNameInstructions")}
                                            invalid={this.state.validation.hasError("securityQuestionAnswer")}
                                        />
                                        {this._errorMessage("securityQuestionAnswer")}

                                    </FormGroup>
                                </Col>

                            </Row>
                        </Form>

                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Button color="primary" className="float-right" onClick={() => this._login()}>
                            {i18n.t("dataSources:NextStep")} <FaChevronRight />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    private async _login() {

        var validation = await this.validateForm();
        if (validation.isOk) {

            this.setState({ loading: true }, async () => {
                try {
                    var result = await PetitionHelper.MakePostPetition(
                        `${this.props.serviceUrl}/TryLogin`, {
                        Username: this.props.username,
                        Password: this.props.password,
                        SecurityQuestionAnswer: this.state.securityQuestionAnswer
                    }) as any;

                    if (result.LoginOK) {
                        this.props.onLoginOK(result.SubAccounts, this.props.username, this.props.password,
                            {
                                text: this.props.securityQuestion,
                                answer: this.state.securityQuestionAnswer,
                            },
                            () => this.props.nextStep()
                        );
                    }
                    else {
                        validation.addError("securityQuestionAnswer", this.props.i18n.t("dataSources:WrongSecurityQuestion"));
                        this.setState({
                            validation: validation,
                            //explanation: this.props.wrongLoginExplanation
                        });
                    }
                }
                catch (error) {
                    alert(error);
                }
                finally {
                    this.setState({ loading: false });
                }
            });
        }
        else {
            this.setState({ validation: validation });
        }
    }

    private async validateForm(): Promise<ModelValidation> {
        var validation = new ModelValidation();

        if ((this.state.securityQuestionAnswer || "") === "") {
            validation.addError("securityQuestionAnswer", this.props.i18n.t("dataSources:Required"));
        }

        return validation;
    }

    private _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validation.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }
}


export const Step2 = withTranslation(["dataSources"])(Step2Comp);
