import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ClientContext } from './security/ClientContext';
import { withTranslation, WithTranslationProps } from 'react-i18next';

class NavMenuComp extends Component<WithTranslationProps, any> {
    static displayName = NavMenuComp.name;

    constructor(props: WithTranslationProps) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { i18n } = this.props;

        return (
            <Navbar expand="xl" className="navbar navbar-toggleable-xl navbar-fixed-left private" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">
                        <img className="logo" src="/assets/img/logo.png" alt="" />
                    </NavbarBrand>
                    <div className="nombre_usuario d-none d-sm-block d-xl-none">
                        <span>{i18n.t("home:hello")},</span> {ClientContext.current.name}
                    </div>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <ul>
                            <NavItem>
                                <div className="nombre_usuario d-sm-none">
                                    <span>{i18n.t("home:hello")},</span> {ClientContext.current.name}
                                </div>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/">

                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M13.5713 1.66669H16.2934C17.4197 1.66669 18.3334 2.58827 18.3334 3.72517V6.47011C18.3334 7.60615 17.4197 8.52859 16.2934 8.52859H13.5713C12.4442 8.52859 11.5305 7.60615 11.5305 6.47011V3.72517C11.5305 2.58827 12.4442 1.66669 13.5713 1.66669Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.70744 1.66669H6.42875C7.55587 1.66669 8.46952 2.58827 8.46952 3.72517V6.47011C8.46952 7.60615 7.55587 8.52859 6.42875 8.52859H3.70744C2.58032 8.52859 1.66667 7.60615 1.66667 6.47011V3.72517C1.66667 2.58827 2.58032 1.66669 3.70744 1.66669Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.70744 11.4714H6.42875C7.55587 11.4714 8.46952 12.393 8.46952 13.5308V16.2749C8.46952 17.4118 7.55587 18.3333 6.42875 18.3333H3.70744C2.58032 18.3333 1.66667 17.4118 1.66667 16.2749V13.5308C1.66667 12.393 2.58032 11.4714 3.70744 11.4714Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.5713 11.4714H16.2934C17.4197 11.4714 18.3334 12.393 18.3334 13.5308V16.2749C18.3334 17.4118 17.4197 18.3333 16.2934 18.3333H13.5713C12.4442 18.3333 11.5305 17.4118 11.5305 16.2749V13.5308C11.5305 12.393 12.4442 11.4714 13.5713 11.4714Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    {i18n.t("navMenu:Home")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/songs">

                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <g clipPath="url(#clip0)">
                                            <path d="M20.7556 5.12903C20.6019 5.0128 20.4025 4.97527 20.217 5.02777L11.5217 7.48869C11.2569 7.56367 11.074 7.80542 11.074 8.08067V16.4098C10.7117 16.1996 10.2917 16.0787 9.84356 16.0787C8.4866 16.0787 7.38263 17.1827 7.38263 18.5396C7.38263 19.8966 8.4866 21.0005 9.84356 21.0005C11.2005 21.0005 12.3045 19.8966 12.3045 18.5396C12.3045 18.4757 12.3045 11.0069 12.3045 11.0069L19.7693 8.89417V13.9488C19.407 13.7387 18.9869 13.6178 18.5388 13.6178C17.1819 13.6178 16.0779 14.7217 16.0779 16.0787C16.0779 17.4356 17.1819 18.5396 18.5388 18.5396C19.8958 18.5396 20.9998 17.4356 20.9998 16.0787C20.9998 16.0241 20.9998 5.61974 20.9998 5.61974C20.9998 5.42697 20.9094 5.24531 20.7556 5.12903ZM9.8436 19.7701C9.16512 19.7701 8.61313 19.2181 8.61313 18.5396C8.61313 17.8611 9.16512 17.3092 9.8436 17.3092C10.5221 17.3092 11.0741 17.8611 11.0741 18.5396C11.0741 19.2181 10.5221 19.7701 9.8436 19.7701ZM18.5389 17.3092C17.8604 17.3092 17.3084 16.7572 17.3084 16.0787C17.3084 15.4002 17.8604 14.8482 18.5389 14.8482C19.2174 14.8482 19.7693 15.4002 19.7693 16.0787C19.7693 16.7572 19.2174 17.3092 18.5389 17.3092ZM19.7693 7.61543L12.3045 9.7281V8.54595L19.7693 6.43328V7.61543Z" />
                                            <path d="M8.58571 4.36778C7.22071 3.36716 6.22072 2.37615 5.52862 1.33801L4.8195 0.274435C4.6691 0.0488498 4.38876 -0.0516791 4.12934 0.0268655C3.86987 0.105451 3.6924 0.344612 3.6924 0.615725V10.2573C3.33011 10.0472 2.91003 9.92623 2.46194 9.92623C1.10498 9.92623 0.00100708 11.0302 0.00100708 12.3872C0.00100708 13.7441 1.10498 14.8481 2.46194 14.8481C3.81889 14.8481 4.92286 13.7441 4.92286 12.3872V2.60059C5.65815 3.548 6.62455 4.45588 7.85822 5.36023C8.33162 5.70722 8.61426 6.26417 8.61426 6.85012C8.61426 7.86783 7.78628 8.69581 6.76856 8.69581C6.42879 8.69581 6.15333 8.97127 6.15333 9.31104C6.15333 9.65082 6.42879 9.92628 6.76856 9.92628C8.46475 9.92623 9.84472 8.54627 9.84472 6.85007C9.84472 5.87362 9.37403 4.94564 8.58571 4.36778ZM2.46194 13.6176C1.78346 13.6176 1.23147 13.0656 1.23147 12.3872C1.23147 11.7087 1.78346 11.1567 2.46194 11.1567C3.14041 11.1567 3.6924 11.7087 3.6924 12.3872C3.6924 13.0656 3.14041 13.6176 2.46194 13.6176Z" opacity="0.4" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="21" height="21" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>



                                    {i18n.t("navMenu:Songs")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/importations">

                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <path opacity="0.4" d="M7.0816 8.60967H6.18748C4.23727 8.60967 2.65602 10.1909 2.65602 12.1411L2.65602 16.813C2.65602 18.7623 4.23727 20.3435 6.18748 20.3435H16.8537C18.8039 20.3435 20.3852 18.7623 20.3852 16.813V12.1315C20.3852 10.1871 18.8087 8.60967 16.8643 8.60967H15.9606" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.5205 2.0992V13.6385" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.72687 4.90552L11.5204 2.09952L14.3149 4.90552" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>


                                    {i18n.t("navMenu:Importations")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/datasources">

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M21.524 9.75284C20.8849 5.81944 17.5439 2.89321 13.5606 2.77801C13.3712 2.77059 13.1867 2.83878 13.0476 2.96755C12.9085 3.09632 12.8264 3.27511 12.8192 3.46451V3.46451V3.52859L13.2677 10.238C13.2971 10.6896 13.6854 11.0329 14.1373 11.0068L20.865 10.5583C21.0545 10.5443 21.2307 10.4553 21.3544 10.311C21.4781 10.1666 21.5392 9.97894 21.524 9.78946V9.75284Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.9016 6.76889C9.32956 6.6699 9.7675 6.88887 9.94508 7.29063C9.9916 7.38512 10.0196 7.48766 10.0275 7.59269C10.119 8.89246 10.3112 11.7391 10.4211 13.2769C10.4398 13.5539 10.5682 13.812 10.7778 13.9941C10.9873 14.1762 11.2608 14.2673 11.5378 14.2472V14.2472L17.1854 13.8993C17.4375 13.8842 17.6847 13.9739 17.8684 14.1472C18.0521 14.3205 18.1561 14.5621 18.1556 14.8147V14.8147C17.9267 18.225 15.4765 21.0761 12.1393 21.8151C8.80211 22.5541 5.37727 21.0041 3.72998 18.0092C3.23823 17.1472 2.92681 16.1943 2.81464 15.2083C2.76669 14.9056 2.7483 14.5991 2.75972 14.2929C2.76944 10.6509 5.32723 7.51285 8.89245 6.76889" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>


                                    {i18n.t("navMenu:DataSources")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/my-profile">

                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <ellipse opacity="0.4" cx="11.0965" cy="6.49563" rx="4.57895" ry="4.57895" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.83334 17.443C3.83211 17.1211 3.9041 16.8031 4.04387 16.5131C4.48247 15.6359 5.71931 15.171 6.74562 14.9605C7.4858 14.8025 8.2362 14.697 8.99124 14.6447C10.3891 14.5219 11.7951 14.5219 13.193 14.6447C13.948 14.6976 14.6983 14.8031 15.4386 14.9605C16.4649 15.171 17.7018 15.5921 18.1404 16.5131C18.4214 17.1042 18.4214 17.7905 18.1404 18.3815C17.7018 19.3026 16.4649 19.7237 15.4386 19.9254C14.6993 20.0899 13.9486 20.1984 13.193 20.25C12.0552 20.3464 10.9121 20.364 9.77194 20.3026C9.50878 20.3026 9.2544 20.3026 8.99124 20.25C8.23843 20.199 7.49063 20.0905 6.7544 19.9254C5.71931 19.7237 4.49124 19.3026 4.04387 18.3815C3.90482 18.0882 3.8329 17.7676 3.83334 17.443Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    {i18n.t("navMenu:Profile")}
                                </NavLink>
                            </NavItem>
                            <NavItem className="d-xl-none">
                                <NavLink tag={Link} className="text-dark" to="/"
                                    onClick={() => {
                                        ClientContext.logOff();
                                    }}>
                                    <i className="fas fa-sign-out-alt"></i>
                                    {i18n.t("navMenu:LogOff")}
                                </NavLink>
                            </NavItem>
                            <NavItem className="last d-xl-none">
                                <select className="form-control-sm" value={this.props.i18n.language} onChange={(evt) => this._languageChange(evt.target.value)}>
                                    <option value="es">Espa&ntilde;ol</option>
                                    <option value="en">English</option>
                                </select>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }

    private _languageChange(language: string): void {
        this.props.i18n.changeLanguage(language);
    }

}


export const NavMenu = withTranslation(["navMenu"], { withRef: true })(NavMenuComp);