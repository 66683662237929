import * as React from "react";
import { BarChart, XAxis, CartesianGrid, Bar, ResponsiveContainer, Tooltip, YAxis } from "recharts";
import { SongDataItem } from "../../models/SongDataItem";
import { SongsDataService } from "./SongsDataService";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { formatCurrencyNoSymbol } from "../common/Formatter";
import { SpinnerInline } from "../common/SpinnerInline";



interface SongsSalesByYearState {
    data: SongDataItem[];
    loading: boolean;
}


class TopSongsBySalesComp extends React.Component<WithTranslationProps, SongsSalesByYearState> {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true
        }
    }

    async componentDidMount() {
        let dataResponse = await SongsDataService.getSongsData({
            fromDate: null,
            toDate: new Date(),
            pageNumber: 0,
            pageSize: 50,
            showTotal: false
        });



        this.setState({
            loading: false,
            data: dataResponse.Data,
        });
    }

    render() {

        if (this.state.loading) {
            return <SpinnerInline />;
        } else if (this.state.data == null || this.state.data.length === 0) {
            return <div></div>;
        }

        const i18n = this.props.i18n;

        return <ResponsiveContainer className="mt-lg" aspect={5} width="100%">
            <BarChart data={this.state.data}>

                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey="Title"
                    className="xaxis-ablanco"
                    tickMargin={15}
                    tick={false}
                />

                <YAxis axisLine={false} tickLine={false}
                    tickFormatter={(val, index) => formatCurrencyNoSymbol(i18n.language, val)}
                />

                <Bar barSize={20} dataKey="TotalInUSD" name="Total in USD" fill="#289D44" width={20} radius={[5, 5, 0, 0]} />
                <Tooltip cursor={{ visibility: "hidden" }} formatter={(val) => formatCurrencyNoSymbol(this.props.i18n.language, val)} />
            </BarChart>
        </ResponsiveContainer>;
    }
}

export const TopSongsBySales = withTranslation(["songs"])(TopSongsBySalesComp);
