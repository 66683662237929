import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button } from "reactstrap";
import { DataSourceItem, DataSourceService } from "./DataSourceService";

import { Spinner } from "../common/Spinner";

import { UserConfirmationPopup } from "../common/UserConfirmationPopup";
import { FaRedo, FaTrashAlt } from "react-icons/fa";
import { DataSources } from "../utils/ImagePaths";
import { formatCurrency } from "../common/Formatter";
import { Reimport as BMIReimport } from "./BMIWizard/Reimport";
import { Reimport as AscapReimport } from "./AscapWizard/Reimport";
import { Reimport as UnisonReimport } from "./UnisonWizard/Reimport";
import { Reimport as SymphonicReimport } from "./SymphonicWizard/Reimport";
import { Reimport as OneRpmReimport } from "./OneRpmWizard/Reimport";
import { Reimport as DittoReimport } from "./DittoWizard/Reimport";
import { Reimport as SoundExchangeReimport } from "./SoundExchangeWizard/Reimport";
import { Reimport as WarnerChappellReimport } from "./WarnerChappellWizard/Reimport";
import { Reimport as TuneCoreReimport } from "./TuneCoreWizard/Reimport";
import { Reimport as IdentityMusicReimport } from "./IdentityMusicWizard/Reimport";
import { Reimport as UniversalMusicReimport } from "./UniversalMusicWizard/Reimport";

import { BMIWizard } from "./BMIWizard/Wizard";
import { AscapWizard } from "./AscapWizard/Wizard";
import { UnisonWizard } from "./UnisonWizard/Wizard";
import { OneRpmWizard } from "./OneRpmWizard/Wizard";
import { SymphonicWizard } from "./SymphonicWizard/Wizard";
import { DittoWizard } from "./DittoWizard/Wizard";
import { SoundExchangeWizard } from "./SoundExchangeWizard/Wizard";
import { WarnerChappellWizard } from "./WarnerChappellWizard/Wizard";
import { TuneCoreWizard } from "./TuneCoreWizard/Wizard";
import { IdentityMusicWizard } from "./IdentityMusicWizard/Wizard";
import { UniversalMusicWizard } from "./UniversalMusicWizard/Wizard";

interface DataSourcesProps {

}

interface DataSourcesState {

    loading: boolean;
    datasources: DataSourceItem[];
    dataSourceEditId: DataSources;

    musicianDataSourceToDeleteId: number;

    musicianDataSourceToRefresh: DataSources;
}


export class DataSourcesListComp extends React.Component<WithTranslationProps & DataSourcesProps, DataSourcesState> {

    public constructor(props: DataSourcesProps) {
        super(props);
        this.state = {
            loading: true,
            datasources: null,
            dataSourceEditId: null,

            musicianDataSourceToDeleteId: null,
            musicianDataSourceToRefresh: null
        };
    }

    async componentDidMount() {

        await this._performSearch();
    }

    private async _performSearch() {
        var items = await DataSourceService.getMyDataSources();
        this.setState({
            loading: false,
            dataSourceEditId: null,
            musicianDataSourceToDeleteId: null,
            datasources: items
        });
    }

    render() {

        const { i18n } = this.props;

        if (this.state.loading && this.state.datasources == null) {
            return <Spinner />;
        }

        return <div>

            {this.state.loading && <Spinner />}

            {this.state.datasources.length === 0 && <div>{i18n.t("dataSources:EmptyList")}</div>}
            {this.state.datasources.length !== 0 &&
                <table className="table table-striped table-sm table-hover">
                    <thead className="">
                        <tr>
                            <th>{i18n.t("dataSources:TableName")}</th>
                            <th>{i18n.t("dataSources:TableNumImportations")}</th>
                            <th>{i18n.t("dataSources:TableLastImportation")}</th>
                            <th>{i18n.t("dataSources:TableTotalInUSD")}</th>
                            <th>{i18n.t("dataSources:TableConfigure")}</th>
                            <th>{i18n.t("dataSources:TableReset")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.datasources.map((mds, index) => {
                            return <tr key={index}>
                                <td>
                                    <strong>{mds.DataSource}</strong>
                                </td>

                                <td>{mds.NumImportations}</td>
                                <td>
                                    {mds.LastImportation == null ? null : mds.LastImportation.toLocaleDateString()}
                                </td>
                                <td>
                                    {mds.TotalInUSD != null ? formatCurrency(i18n.language, mds.TotalInUSD) : "-"}
                                </td>
                                <td>
                                    {mds.AutomaticImportation && !mds.Configured &&
                                        <Button color="secondary" size="sm" onClick={() => this.setState({ dataSourceEditId: mds.DataSourceId })}>
                                            {i18n.t("dataSources:ConfigButton")}
                                        </Button>
                                    }
                                    {mds.Configured &&
                                        <React.Fragment>
                                            <span className="text-success">{i18n.t("dataSources:Yes")}</span>

                                            <Button
                                                onClick={() => { this.setState({ musicianDataSourceToDeleteId: mds.Id }) }}
                                                className="btn btn-sm ml-4"
                                            >
                                                <FaTrashAlt />
                                            </Button>
                                        </React.Fragment>
                                    }
                                </td>
                                <td>
                                    {this.ButtonRefresh(mds, DataSources.BMI)}
                                    {this.ButtonRefresh(mds, DataSources.ASCAP)}
                                    {this.ButtonRefresh(mds, DataSources.Unison)}
                                    {this.ButtonRefresh(mds, DataSources.Symphonic)}
                                    {this.ButtonRefresh(mds, DataSources.OneRpm)}
                                    {this.ButtonRefresh(mds, DataSources.Ditto)}
                                    {this.ButtonRefresh(mds, DataSources.SoundExchange)}
                                    {this.ButtonRefresh(mds, DataSources.WarnerChappell)}
                                    {this.ButtonRefresh(mds, DataSources.TuneCore)}
                                    {this.ButtonRefresh(mds, DataSources.IdentityMusic)}
                                    {this.ButtonRefresh(mds, DataSources.UniversalMusic)}
                                </td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            }

            {this.state.dataSourceEditId === DataSources.BMI && <BMIWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.ASCAP && <AscapWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.Unison && <UnisonWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.Symphonic && <SymphonicWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.OneRpm && <OneRpmWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.Ditto && <DittoWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.SoundExchange && <SoundExchangeWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.WarnerChappell && <WarnerChappellWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.TuneCore && <TuneCoreWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.IdentityMusic && <IdentityMusicWizard
                onChange={async () => await this.onFinishRefresh()}
            />}
            {this.state.dataSourceEditId === DataSources.UniversalMusic && <UniversalMusicWizard
                onChange={async () => await this.onFinishRefresh()}
            />}

            {this.state.musicianDataSourceToRefresh === DataSources.BMI && <BMIReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}

            {this.state.musicianDataSourceToRefresh === DataSources.ASCAP && <AscapReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}
            {this.state.musicianDataSourceToRefresh === DataSources.Unison && <UnisonReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}

            {this.state.musicianDataSourceToRefresh === DataSources.Symphonic && <SymphonicReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}

            {this.state.musicianDataSourceToRefresh === DataSources.OneRpm && <OneRpmReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}
            {this.state.musicianDataSourceToRefresh === DataSources.Ditto && <DittoReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}
            {this.state.musicianDataSourceToRefresh === DataSources.SoundExchange && <SoundExchangeReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}
            {this.state.musicianDataSourceToRefresh === DataSources.WarnerChappell && <WarnerChappellReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}
            {this.state.musicianDataSourceToRefresh === DataSources.TuneCore && <TuneCoreReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}
            {this.state.musicianDataSourceToRefresh === DataSources.IdentityMusic && <IdentityMusicReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}
            {this.state.musicianDataSourceToRefresh === DataSources.UniversalMusic && <UniversalMusicReimport
                onProcessFinished={async () => await this.onFinishRefresh()}
            />}

            {this.state.musicianDataSourceToDeleteId != null &&
                <UserConfirmationPopup
                    visible={true}
                    title={i18n.t("dataSources:DeletePopupTitle")}
                    bodyText={i18n.t("dataSources:DeletePopupBody")}
                    danger={true}
                    confirmTextButton={i18n.t("dataSources:DeletePopupOk")}
                    cancelTextButton={i18n.t("dataSources:DeletePopupCancel")}
                    onCancel={() => this.setState({ musicianDataSourceToDeleteId: null })}
                    onConfirm={() => {
                        this.setState({ loading: true }, async () => {

                            await DataSourceService.deleteDataSource(this.state.musicianDataSourceToDeleteId);
                            await this._performSearch();
                        });
                    }}
                />
            }
        </div>;
    }

    private ButtonRefresh(mds: DataSourceItem, dataSource: DataSources): JSX.Element {
        return <React.Fragment>
            {mds.DataSourceId === dataSource && mds.Configured &&
                <Button color="primary" size="sm" className="mr-2"
                    onClick={() => this.setState({ musicianDataSourceToRefresh: dataSource })}
                >
                    <FaRedo />{' '}
                    {this.props.i18n.t("dataSources:Refresh")}
                </Button>
            }
        </React.Fragment>;
    }

    private async onFinishRefresh() {

        var items = await DataSourceService.getMyDataSources();
        this.setState({
            loading: false,
            datasources: items,
            dataSourceEditId: null,
            musicianDataSourceToRefresh: null
        });
    }
}

export const DataSourcesList = withTranslation(["dataSources"])(DataSourcesListComp);
