import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Button, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { StepWizardChildProps } from "react-step-wizard";
import { ModelValidation } from "../../utils/ModelValidation";
import { cloneDeep } from "lodash";
import { MusicianDataSourceModel } from "../DataSourceService";
import { PetitionHelper } from "../../utils/PetitionHelper";
import { Spinner } from "../../common/Spinner";
import { FaChevronRight, FaEye, FaEyeSlash } from "react-icons/fa";


export interface Step1Props {

    onLoginOK: (accounts: Object,
        username: string,
        password: string,
        question: Question,
        callback: () => void) => void
}

export interface Question {
    text: string;
    answer: string;
}

export interface Step1InternalProps extends Step1Props {
    logoUrl: string;
    serviceUrl: string;
    wrongLoginExplanation: string;
}

interface Step1State {

    loading: boolean;
    datasource: MusicianDataSourceModel;
    validation: ModelValidation;
    passwordRevealed: boolean;
    explanation: string;
}

class Step1Comp extends React.Component<WithTranslationProps & Partial<StepWizardChildProps> & Step1InternalProps, Step1State> {

    public constructor(props: WithTranslationProps & Partial<StepWizardChildProps> & Step1InternalProps) {
        super(props);

        this.state = {
            loading: false,
            datasource: {
                UserName: '',
                Password: ''
            } as MusicianDataSourceModel,
            validation: new ModelValidation(),
            passwordRevealed: false,
            explanation: null
        };
    }

    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        const { i18n } = this.props;

        return (
            <div>
                <h3 className='text-center'>{i18n.t("dataSources:Step1")}</h3>
                <Row>
                    <Col md={3}>
                        <img className="me-3 logo mx-auto" src={this.props.logoUrl} alt="" />
                    </Col>
                    <Col md={9}>
                        {i18n.t("dataSources:Step1Description")}

                        <Form onSubmit={(evt: React.FormEvent) => {
                            evt.preventDefault();
                            this._login()
                            return false;
                        }}>
                            <Row className="mt-5">
                                <Col md={12} lg={6}>
                                    <FormGroup>
                                        <Label for="UserName">{i18n.t("dataSources:UserName")}</Label>
                                        <Input type="text" name="UserName" id="UserName"
                                            value={this.state.datasource.UserName}
                                            onChange={(evt) => {
                                                var newState = cloneDeep(this.state);
                                                newState.datasource.UserName = evt.target.value;
                                                this.setState(newState);
                                            }}
                                            placeholder={i18n.t("dataSources:UserNameInstructions")}
                                            invalid={this.state.validation.hasError("UserName")}
                                        />
                                        {this._errorMessage("UserName")}

                                    </FormGroup>
                                </Col>
                                <Col md={12} lg={6}>
                                    <FormGroup>
                                        <Label for="Password">{i18n.t("dataSources:Password")}</Label>

                                        <InputGroup className={this.state.validation.hasError("Password") != null ? "is-invalid" : null}>
                                            <Input type={this.state.passwordRevealed ? "text" : "password"}
                                                name="Password" id="Password"
                                                value={this.state.datasource.Password}
                                                onChange={(evt) => {
                                                    var newState = cloneDeep(this.state);
                                                    newState.datasource.Password = evt.target.value;
                                                    this.setState(newState);
                                                }}
                                                placeholder={i18n.t("dataSources:PasswordInstructions")}
                                                invalid={this.state.validation.hasError("Password")}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button onClick={() => this.setState({ passwordRevealed: !this.state.passwordRevealed })}>
                                                    {this.state.passwordRevealed && <FaEyeSlash />}
                                                    {!this.state.passwordRevealed && <FaEye />}
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        {this._errorMessage("Password")}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                        {this.state.explanation != null &&
                            <p className="lead text-danger">{this.state.explanation}</p>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Button color="primary" className="float-right" onClick={() => this._login()}>
                            {i18n.t("dataSources:NextStep")} <FaChevronRight />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    private async _login() {

        var validation = await this.validateForm();
        if (validation.isOk) {

            this.setState({ loading: true }, async () => {
                try {
                    var result = await PetitionHelper.MakePostPetition(
                        `${this.props.serviceUrl}/TryLogin`, {
                        Username: this.state.datasource.UserName,
                        Password: this.state.datasource.Password
                    }) as any;

                    if (result.LoginOK) {
                        this.props.onLoginOK(result.SubAccounts, this.state.datasource.UserName, this.state.datasource.Password,
                            {
                                text: result.SecurityQuestion,
                                answer: result.SecurityQuestionAnswer,
                            },                            
                            () => this.props.nextStep()
                        );
                    }
                    else {
                        validation.addError("Password", this.props.i18n.t("dataSources:WrongLogin"));
                        this.setState({
                            validation: validation,
                            explanation: this.props.wrongLoginExplanation
                        });
                    }
                }
                catch (error) {
                    alert(error);
                }
                finally {
                    this.setState({ loading: false });
                }
            });
        }
        else {
            this.setState({ validation: validation });
        }
    }

    private async validateForm(): Promise<ModelValidation> {
        var validation = new ModelValidation();

        if ((this.state.datasource.UserName || "") === "") {
            validation.addError("UserName", this.props.i18n.t("dataSources:Required"));
        }

        if ((this.state.datasource.Password || "") === "") {
            validation.addError("Password", this.props.i18n.t("dataSources:Required"));
        }

        return validation;
    }

    private _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validation.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }
}


export const GenericStep1 = withTranslation(["dataSources"])(Step1Comp);
