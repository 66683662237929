import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { ImportationsDataModel, ImportationService } from "./ImportationService";
import { Spinner } from "../common/Spinner";
import { Link } from "react-router-dom";
import { formatCurrencyNoSymbol } from "../common/Formatter";

interface ImportationsListState {

    loading: boolean;
    searchResults: ImportationsDataModel;
}


export class ImportationsHomeListComp extends React.Component<WithTranslationProps, ImportationsListState> {

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            searchResults: null,
        };
    }

    async componentDidMount() {

        var items = await ImportationService.getMyImportations(0, 5, true);
        this.setState({
            loading: false,            
            searchResults: items
        });
    }

    render() {

        if (this.state.loading || this.state.searchResults == null) {
            return <Spinner />;
        }
        const { i18n } = this.props;

        if ((this.state.searchResults.Data || []).length === 0) {
            return <div>{i18n.t("importations:EmptyList")}</div>
        }

        return (
            <div className="data-table">
                <h3>{i18n.t("importations:TitleHome")}</h3>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th>{i18n.t("importations:tableDate")}</th>
                            <th>{i18n.t("importations:tableDesc")}</th>
                            <th>{i18n.t("importations:tableAmount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.searchResults.Data.map((ds, index) => {
                            return <tr key={index}>
                                <td>{ds.ImportDate.toLocaleDateString()}</td>
                                <td>
                                    {ds.Name}
                                </td>
                                <td>{formatCurrencyNoSymbol(i18n.language, ds.TotalSales)}</td>
                            </tr>;
                        })}
                    </tbody>
                </table> 

                <Link className="btn btn-primary btn-sm float-right" to="/importations">
                    {i18n.t("importations:viewMore")} 
                </Link>
            </div>
        );
    }
}

export const ImportationsHomeList = withTranslation(["importations"])(ImportationsHomeListComp);
