import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { PieChart, Pie, BarChart, XAxis, CartesianGrid, Bar, ResponsiveContainer, Cell, Tooltip } from "recharts";
import { SalesByYearItem, SalesByStoreItem } from "../../../models/SongDataItem";
import { formatCurrencyNoSymbol } from "../../common/Formatter";

interface SalesByYearProps {
    data: SalesByYearItem[];
}

interface SalesByStoreProps {
    data: SalesByStoreItem[];
    numStoresToShow: number;
}

const Colors = ['#0088FE', '#00C49F', '#FFBB28', "#04773B", '#FF8042', "#7E5A9B"];

export class SalesByStoreGraphComp extends React.Component<WithTranslationProps & SalesByStoreProps, any> {

    render() {
        return <ResponsiveContainer className="mt-lg" aspect={1} width="100%">
            <PieChart>
                <Pie
                    dataKey="TotalInUSD"
                    isAnimationActive={false}
                    data={this.props.data.slice(0, this.props.numStoresToShow || 20)}
                    cx="50%"
                    cy="40%"
                    outerRadius={180}
                    fill="#8884d8"
                    labelLine={false}
                    label={(entry) => entry.StoreName}
                    nameKey="StoreName"
                >
                    {this.props.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={Colors[index % Colors.length]} />
                    ))}
                </Pie>
                <Tooltip formatter={(val) => formatCurrencyNoSymbol(this.props.i18n.language, val)} />
            </PieChart>
        </ResponsiveContainer>;
    }
};


export const SalesByStoreGraph = withTranslation(["songs"])(SalesByStoreGraphComp);


export class SalesByYearGraphComp extends React.Component<WithTranslationProps & SalesByYearProps , any> {

    render() {
        return (
            <div>
                <ResponsiveContainer className="mt-lg" aspect={2} width="100%">
                    <BarChart data={this.props.data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Year" />
                        <Bar dataKey="TotalInUSD" name="Total in USD" fill="#8884d8" />                        
                        <Tooltip formatter={(val) => formatCurrencyNoSymbol(this.props.i18n.language, val)}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}


export const SalesByYearGraph = withTranslation(["songs"])(SalesByYearGraphComp);