export class ModelValidation {
    public isOk: boolean;

    public validationErrors: { [key: string]: string; };

    constructor() {
        this.isOk = true;
        this.validationErrors = {};
    }

    public load(valResult: ModelValidationResult) {
        this.isOk = valResult.isOk;
        this.validationErrors = valResult.validationErrors;
    }

    public addError(fieldName: string, errorText: string) {
        this.validationErrors[fieldName.toLowerCase()] = errorText;
        this.isOk = false;

    }

    public hasError(fieldName: string) {
        return this.getError(fieldName) != null;
    }

    public getError(fieldName: string) {
        return this.validationErrors[fieldName.toLowerCase()];
    }

    public reset(): void {
        this.isOk = true;
        this.validationErrors = {};
    }
}


export interface ModelValidationResult {
    isOk: boolean;
    validationErrors: { [key: string]: string; } | undefined;
}

export function validateEmail(value: string): boolean {
    let result = true;
    let mailformat = /^((([a-zA-Z0-9_\-])+)(\.{0,1}))+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})$/;


    if (!value.match(mailformat)) {
        result = false;
    }
    return result;
}