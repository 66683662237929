import * as React from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { DataSourcesList } from "./DataSourcesList";

export class IndexComp extends React.Component<WithTranslationProps, any> {


    render() {
        const i18n = this.props.i18n;

        return <React.Fragment>
            <h2 className="mt-5 mb-5">{i18n.t("dataSources:Title")}</h2>

            <DataSourcesList />
        </React.Fragment>
    }
}

export const Index = withTranslation(["dataSources"])(IndexComp);
