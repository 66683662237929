//import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Spinner } from "./components/common/Spinner";

import { unregister } from './registerServiceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';


const rootElement = document.getElementById('root');


const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
if (defaultDark) {
    const body = document.getElementsByTagName("body");
    body[0].classList.add("dark");
}


i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: true,
        detection: {
            order: ['header'],
            lookupCookie: 'lang',            
            caches: ['cookie'],
            cookieMinutes: 525600
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

declare function initTheme(): any;

ReactDOM.render(
    <BrowserRouter>
        <React.Suspense fallback={<Spinner />}>
            <App />
        </React.Suspense>
    </BrowserRouter>,
    rootElement);

unregister();

