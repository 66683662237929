import * as React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip, Legend, Sector } from "recharts";
import { SongsDataService } from "./SongsDataService";
import { SalesByStoreItem } from "../../models/SongDataItem";
import { getFromToDate, SalesPeriod, SalesPeriodDropDown } from "../common/SalesPeriodDropDown";
import { Col, Row } from "reactstrap";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { Spinner } from "../common/Spinner";
import { formatCurrency, formatCurrencyNoSymbol } from "../common/Formatter";


interface SalesByStoreProps {
    numStoresToShow: number;
    salesPeriod: SalesPeriod;
    title: string;
}

interface SalesByStoreState {

    loading: boolean;
    data: SalesByStoreItem[];
    numStoresToShow: number,
    salesPeriod: SalesPeriod
}

const Colors = ["#E8C547",
    "#4D5061",
    "#5C80BC",
    "#CDD1C4",
    "#53A2BE",
    "#132E32",
    "#7E5A9B",
    "#04773B",
    "#953553",
    "#BF40BF",
    "#BDB5D5",
    "#00FF40",
    "#dabfdf",
    "#553333",
    "#669fa8"];


const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

class SongsSalesByStoreComp extends React.Component<WithTranslationProps & SalesByStoreProps, SalesByStoreState> {
    public constructor(props: SalesByStoreProps) {
        super(props);

        this.state = {
            loading: true,
            data: null,
            numStoresToShow: props.numStoresToShow,
            salesPeriod: props.salesPeriod,
        };
    }

    async componentDidMount() {
        await this.searchSales();
    }

    private async searchSales() {

        var period = getFromToDate(this.state.salesPeriod);

        var dataResponse = await SongsDataService.getSongsSalesByStoreData({
            numStores: this.state.numStoresToShow,
            fromDate: period.from,
            toDate: period.to,
        });

        this.setState({
            loading: false,
            data: dataResponse
        });
    }

    render() {
        const { i18n } = this.props;

        if (this.state.loading) {
            return <Spinner />;
        }

        var salesPeriodDropDown = <SalesPeriodDropDown
            selectedItemValue={this.state.salesPeriod}
            onChanged={async (val) => {
                this.setState({ salesPeriod: val, loading: true },
                    () => {
                        this.searchSales();
                    });
            }}
        />;

        return <div className="data-table">
            <Row>
                {this.props.title != null &&
                    <React.Fragment>
                        <Col md={12} lg={7}>
                            <h3>{this.props.title}</h3>
                        </Col>
                        <Col md={6} lg={5}>
                            {salesPeriodDropDown}
                        </Col>
                    </React.Fragment>
                }
                {this.props.title == null &&
                    <Col md={6}>
                        {salesPeriodDropDown}
                    </Col>
                }
            </Row>
            {this.state.data.length === 0 &&
                <Row className="mt-2"><Col md={12}>{i18n.t("songs:emptyList")}</Col></Row>
            }
            {this.state.data != null &&
                <Row style={{ minHeight: "400px" }} className="mt-2">
                    <Col md={6}>
                    <ResponsiveContainer className="mt-lg" height={300}>
                            <PieChart>
                                <Pie
                                    dataKey="TotalInUSD"
                                    isAnimationActive={false}
                                    data={this.state.data.slice(0, this.state.numStoresToShow || 20)}
                                    activeShape={renderActiveShape}
                                    fill="#8884d8"
                                    nameKey="StoreName"
                                    innerRadius="80%"
                                    outerRadius="95%"
                                    paddingAngle={2}
                                >
                                    {this.state.data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={Colors[index % Colors.length]} />
                                    ))}
                                </Pie>
                                <Legend />
                                <Tooltip formatter={(val) => formatCurrencyNoSymbol(this.props.i18n.language, val)} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col md={6}>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>{i18n.t("songs:source")}</th>
                                    <th>{i18n.t("songs:amount")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map((entry, index) => {
                                    return <tr key={index}>
                                        <td className="source">{entry.StoreName.toLowerCase()}</td>
                                        <td>{formatCurrency(i18n.language, entry.TotalInUSD)}</td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>}
        </ div>;
    }
};

export const SongsSalesByStore = withTranslation(["songs"])(SongsSalesByStoreComp);

