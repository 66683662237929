import { PetitionHelper } from "../utils/PetitionHelper";

export class QuoteService {



    static async createQuote(model: CreateQuoteRequestModel): Promise<CreateQuoteResponseModel> {

        var url = `Services/Custom/Quotes/RequestQuote`;

        model.publicMusicianId = model.publicMusicianId ?? -1;
        var result = await PetitionHelper.MakePostPetition(url, model) as CreateQuoteResponseModel;

        return result;
    }

    static async getLastQuote(): Promise<QuoteInfo> {
        var url = `Services/Custom/Quotes/Pending`;

        var result = await PetitionHelper.MakeGetPetition(url) as QuoteInfo;        
        if (result == null) {
            return null;
        }
        if (result.RequestDate != null) {
            result.RequestDate = PetitionHelper.ParseDate(result.RequestDate);
        }
        if (result.ResponseDate != null) {
            result.ResponseDate = PetitionHelper.ParseDate(result.ResponseDate);
        }

        return result;
    }

}


interface CreateQuoteRequestModel {
    publicMusicianId: number;
    extraInfo: string;
}

interface CreateQuoteResponseModel {
    IsOk: boolean;
    Errors: { [field: string]: string };
}

export interface QuoteInfo {
    Id: number;
    RequestDate: Date;
    RequestObservations: string;
    QuoteAmount: number;
    ResponseDate: Date;
    ResponseObservations: string;
}
