import { DropdownItemModel} from "../../models/DropdownItemModel";

import { toPairs } from "lodash";
import React from "react";
import { Input } from "reactstrap";
import { withTranslation, WithTranslationProps } from "react-i18next";


export enum SalesPeriod {

    AllTime = 0,
    LastYear = 1,
    LastQuarter = 2,
    LastMonth = 3

}

const SalesPeriodDropDownComp = function (props: IDropDownProps & WithTranslationProps) {

    
    var options = [] as DropdownItemModel[];
    
    var keys = toPairs(SalesPeriod);
    for (let i = 0; i < keys.length / 2; i++) {
        options.push({
            Id: parseInt(keys[i][0]),
            Name: keys[i][1].toString(),
        } as DropdownItemModel);
    }

    return <Input type="select"
        value={props.selectedItemValue}
        onChange={(evt) => props.onChanged(parseInt(evt.target.value))}
    >
        {options.map((val, index) => {
            return <option key={index} value={val.Id}>
                {props.i18n.t(`home:${val.Name}`)}
            </option>;
        })}
    </Input>;



};

export const SalesPeriodDropDown = withTranslation()(SalesPeriodDropDownComp);

export const getFromToDate = function (period: SalesPeriod): { from: Date, to: Date } {

    let dateIni = new Date();
    let dateEnd = new Date();

    switch (period) {
        case SalesPeriod.AllTime: {
            dateIni = null;
            break;
        }
        case SalesPeriod.LastMonth: {
            dateIni.setMonth(dateIni.getMonth() - 1);
            break;
        }
        case SalesPeriod.LastYear: {
            dateIni.setFullYear(dateIni.getFullYear() - 1);
            break;
        }
        case SalesPeriod.LastQuarter: {
            let quarter = Math.floor((dateIni.getMonth() / 3));
            dateIni = new Date(dateIni.getFullYear(), quarter * 3 - 3, 1);
            dateEnd = new Date(dateIni.getFullYear(), dateIni.getMonth() + 3, 0);

            break;
        }

    }

    return { from: dateIni, to: dateEnd };
}


export interface IDropDownProps {
    selectedItemValue: number;
    onChanged: (value: number) => void;
}
